.question-icon {
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 350px;
  background: #ffffff;

  position: absolute;
  margin-left: 32px;
  z-index: 1001;
}

.triangle-icon {
  position: absolute;
  margin-left: 0px;
  margin-top: 5px;
  z-index: 1002;
}

.box-shadow-shipping {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2), 0px -2px 10px rgba(24, 39, 75, 0.2);
}
