@import '../../common/styles/breakpoints';

.copay-success {
  text-align: center;
  font-family: CircularStd;
  .copay-success__checkmark-img {
    margin-top: 174px;
    margin-bottom: 51px;
    width: 141px;
  }
  h1 {
    font-size: 42px;
    line-height: 1.98;
    letter-spacing: -0.5px;
    color: #2f2f2f;
    margin-top: 0px;
  }
  h2 {
    font-size: 42px;
    letter-spacing: -0.3px;
    color: #000000;
    margin-bottom: 130px;
  }
  h3 {
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #394248;
    margin-bottom: 46px;
    padding: 0px 30px;
  }
  button {
    width: 377px;
    height: 75px;
    border-radius: 55px;
    box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
    background-color: #44bdd0;
    font-family: CircularStd;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.4px;
    text-align: center;
    color: #ffffff;
    outline: none;
    transition: all 0.2s ease-in;
    border: none;
    &:disabled {
      background-color: #d3d3d3;
      color: #838383;
      opacity: 0.8;
    }
    &:not(:disabled):hover {
      background-color: darken(#44bdd0, 10%);
      cursor: pointer;
    }
  }
  .checkout__certifications-container {
    padding-top: 194px;
  }
}
@include screen-md {
  .copay-success {
    .copay-success__checkmark-img {
      width: 63px;
      margin-top: 50px;
      margin-bottom: 35px;
    }
    h1 {
      font-size: 36px;
      line-height: 1.6;
      letter-spacing: -0.3px;
      margin-bottom: 0;
      color: #2f2f2f;
    }
    h2 {
      font-size: 20px;
      margin-bottom: 66px;
    }
    h3 {
      font-size: 16px;
      letter-spacing: 0.3px;
      margin-bottom: 26px;
    }
    button {
      width: 232px;
      height: 52px;
      border-radius: 55px;
      font-size: 20px;
      margin-bottom: 90px;
    }
    .checkout__certifications-container {
      display: none;
    }
    .checkout__questions-container {
      margin-top: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}
