@import 'breakpoints';

.testimonials__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // align-items: space-between;
  font-family: CircularStd;
  // padding: 4rem 21% 4rem;
  padding: 4rem 0;

  // width: 100%;
  background-color: rgba(68, 189, 208, 0.05);
}

.testimonials__header {
  width: 241px;
  font-family: CircularStd;
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #293134;
}

.testimonials__quote {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(41, 49, 52, 0.65);
  max-width: 30rem;
}

.testimonials__name-location {
  width: 271px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.86;
  letter-spacing: 0.2px;
  text-align: center;
  color: #293134;
}

.testimonial__container {
  display: flex;
  margin-top: 2rem;
  max-width: 40.625rem;
}

.testimonial__icon {
  height: 124px;
  width: 124px;
  margin-right: 3rem;
}

@include screen-lg {
  .testimonial__icon {
    align-self: center;
    margin: 0;
  }
  .testimonials__quote-container {
    margin-top: 2rem;
  }

  .testimonial__container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100vw;
    padding: 0 15%;
  }
}
