@import '../../common/styles/breakpoints';

div.welcome-message {
  width: 100%;
  background-color: #44bdd0;
  position: relative;
  padding: 1rem;
  transition: margin 0.3s ease-in-out, height 0.3s ease-in-out, padding 0.3s ease-in-out 0.1s;
  overflow: hidden;
  display: flex;
  justify-items: center;

  .welcome-message--text-wrapper {
    width: 1000px;
    margin: 0 auto;
  }

  .welcome-message--text {
    font-family: CircularStd;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    padding-right: 20px;

    .welcome-message__instr-mobile {
      display: none;
    }

    @media screen and (max-width: 1024px) {
      .welcome-message__instr-mobile {
        display: inline;
      }
      .welcome-message__instr-lg {
        display: none;
      }
    }
  }

  .welcome-message--close {
    position: absolute;
    top: 25px;
    right: 36px;
    width: 20px;
    height: 22px;
    margin-left: 81px;
    color: white;
  }

  .welcome-message--close:hover {
    cursor: pointer;
  }

  @include screen-md {
    margin-bottom: 0px;

    .welcome-message--text {
      font-size: 16px;
      line-height: 1.63;
    }
    .welcome-message--close {
      top: 11px;
      right: 9px;
      width: 12px;
      height: 13px;
    }
  }

  &.welcome-message--collapsed {
    height: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }

  @include screen-sm {
    .welcome-message--text {
      font-size: 16px;
      line-height: 1.63;
    }
    .welcome-message--close {
      top: 11px;
      right: 9px;
      width: 12px;
      height: 13px;
    }
  }

  &.welcome-message--collapsed {
    height: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}
