@import '../styles/breakpoints';

.footer__container {
  font-family: Roboto;
  height: 100%;
  min-height: 190px;
  background-color: #44bdd0;
  padding: 100px 19.86vw 50px 19.86vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 100px; */

  .footer__main {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;

    .footer__company-section {
      font-family: CircularStd;
      margin-right: 49px;

      .footer__logo {
        margin-bottom: 24px;
        img.footer__logo--img {
          width: 85px;
          height: 33px;
          opacity: 0.5;
        }
      }

      .footer__contact-info {
        display: flex;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.65);
        font-size: 15px;
        letter-spacing: 0.25px;
        line-height: 23px;
        width: 170px;
        height: auto;
        justify-content: space-between;
        margin-bottom: 18px;

        span.oval {
          display: none;
        }

        a {
          color: rgba(255, 255, 255, 0.65);
          &:hover {
            cursor: pointer;
          }
        }
      }

      .footer__copyright-notice {
        grid-area: copyright;
        display: flex;
        flex-direction: column;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 23px;
        letter-spacing: 0.25px;
        color: rgba(255, 255, 255, 0.65);
      }
    }

    .footer__divider {
      height: 100%;
      min-height: 190px;
      width: 1px;
      opacity: 0.16;
      background-color: #fff;
      margin-right: 52px;
    }

    .footer__support-section--container {
      display: flex;
      justify-content: space-around;
      width: 100%;
      font-family: CircularStd;

      .footer__support-section--group {
        font-size: 15px;
        line-height: 26px;
        min-width: 108px;
        max-width: 128px;

        h4.footer__support-section--header {
          letter-spacing: 0.25px;
          color: #fff;
        }

        ul {
          li.footer__support-section--group-item {
            color: rgba(255, 255, 255, 0.65);
            &:hover {
              color: rgba(255, 255, 255, 0.65);
              text-decoration: underline;
              cursor: pointer;
            }

            a {
              color: rgba(255, 255, 255, 0.65);
            }
          }
        }
      }
    }
  }
}

.footer-mobile__container {
  display: none;
}

/* Intended for mobile views */
@include screen-md {
  .footer__container {
    display: none;
  }

  .footer__logo--img {
    width: 56px;
    opacity: 0.5;
  }

  .footer-mobile__container {
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    // align-items: center;
    background-color: #44bdd0;
    width: 100%;
    height: 172px;
    font-family: CircularStd;
    // padding: 0 7%;
    font-size: 14px;
    letter-spacing: 0.22px;
    padding: 1.6rem;
  }

  .footer-mobile__bottom-section {
    display: flex;
    margin-top: 1rem;
  }
  .mobile-footer__info-container {
    height: 75px;
    width: 40%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    p {
      font-family: CircularStd;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0.2px;
      color: rgba(255, 255, 255, 0.65);
    }
    p:nth-of-type(3) {
      margin-top: 0.5rem;
    }
  }
  .mobile-footer__link-container {
    width: 60%;
    margin-left: 1rem;
    justify-content: space-evenly;
    a {
      font-family: CircularStd;
      font-size: 12px;
      font-weight: 100;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0.2px;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }
  .mobile-footer__link-section-company {
    display: flex;
    flex-direction: column;
  }
  .mobile-footer__link-section-legal {
    display: flex;
    flex-direction: column;
  }

  h4 {
    font-family: CircularStd;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    // font-weight: bold;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.75);
  }
  .mobile-footer__link-container {
    display: flex;
    width: 60%;
  }
}
