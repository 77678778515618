@import '../../common/styles/breakpoints';

@mixin input-styles {
  border: 1px solid rgba(175, 188, 198, 0.55);
  border-radius: 3px;
  width: 100%;
  height: 50px;
  font-family: CircularStd;
  font-size: 16px;
  padding-left: 1rem;
}

@mixin input-placeholder {
  color: rgba(57, 66, 72, 0.5);
  letter-spacing: 0.3px;
}

@mixin grid-gaps {
  grid-column-gap: 22px;
  grid-row-gap: 15px;
}

div.shipping-payment__container {
  width: 100%;
  max-width: 1000px;

  input:focus {
    outline: none;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.5;
  }

  div.shipping-info__container {
    display: flex;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 7px;
    margin-top: 60px;
    margin-bottom: 40px;
    flex-direction: column;

    div.shipping-inputs__container {
      input {
        @include input-styles;
      }

      input::placeholder {
        @include input-placeholder;
      }

      width: calc(100% - 80px);
      display: grid;
      @include grid-gaps;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(5, 1fr);

      .shipping-inputs__header {
        grid-column: 1 / 4;
        grid-row: 1;
      }

      .shipping-inputs__street1 {
        grid-column: 1 / 4;
        grid-row: 2;
      }

      .shipping-inputs__street2 {
        grid-column: 4 / 7;
        grid-row: 2;
      }

      .shipping-inputs__city {
        grid-column: 1 / 4;
        grid-row: 3;
      }

      .shipping-inputs__dropdown-select-state {
        grid-column: 4 / 6;
        grid-row: 3;
        width: 100%;
        padding: 0px;
      }

      .shipping-inputs__zip {
        grid-column: 1 / 4;
        grid-row: 4;
      }

      .shipping-inputs__shipping-dropdown {
        grid-column: 1 / 7;
        grid-row: 5;
        padding: 0;
      }

      //Material UI Stuff - TODO - check for obsolete classes above
      // MuiInputBase-input MuiOutlinedInput-input sets height
      .MuiInputBase-input {
        box-sizing: border-box;
        border: none;
      }

      .MuiInputLabel-formControl {
        top: -3px;
      }

      .MuiSelect-selectMenu {
        @include input-styles;
        border: none;
      }

      .MuiSelect-select {
        position: relative;
        top: -3px;
      }

      .MuiSelect-select:focus {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    div.dropdown-select__state__control,
    div.shipping-inputs--shipping-dropdown__control {
      padding: 0px;
      align-content: center;
      height: 50px;
    }

    div.dropdown-select__state__value-container,
    div.shipping-inputs--shipping-dropdown__value-container {
      padding-left: 16px;
    }

    div.dropdown-select__state__placeholder,
    div.shipping-inputs--shipping-dropdown__placeholder {
      @include input-placeholder;
    }

    div.shipping-info__save-button-container {
      width: 40px;
      margin: 0 20px;
      color: #44bdd0;
      font-size: 22px;
    }

    @include screen-md {
      div.shipping-inputs__container {
        width: 100%;
        grid-template-rows: repeat(6, 1fr);

        .shipping-inputs__header {
          grid-column: 1 / 7;
        }

        .shipping-inputs__street1 {
          grid-column: 1 / 7;
        }

        .shipping-inputs__street2 {
          grid-column: 1 / 7;
          grid-row: 3;
        }

        .shipping-inputs__city {
          grid-column: 1 / 7;
          grid-row: 4;
        }

        .shipping-inputs__dropdown-select-state {
          grid-column: 1 / 4;
          grid-row: 5;
        }

        .shipping-inputs__zip {
          grid-column: 4 / 7;
          grid-row: 5;
        }

        .shipping-inputs__shipping-dropdown {
          grid-row: 6;
        }
      }

      div.shipping-info__save-button-container {
        position: absolute;
        right: 10px;
        font-size: 16px;
        letter-spacing: 0.3px;
      }
    }
  }

  div.stripe__container {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    border-radius: 7px;

    input::placeholder {
      @include input-placeholder;
    }

    div.stripe__card-element-container {
      display: grid;
      @include grid-gaps;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, 1fr);
      width: calc(100% - 80px);

      .stripe-card__header {
        grid-column: 1 / 6;
        grid-row: 1;
      }
      .stripe-card__card-number {
        grid-column: 1 / 7;
        grid-row: 2;
      }

      .stripe-card__cardholder-name-div {
        grid-column: 1 / 4;
        grid-row: 3;
      }
      .stripe-card__expiry {
        grid-column: 4 / 6;
        grid-row: 3;
      }
      .stripe-card__cvc {
        grid-column: 6 / 7;
        grid-row: 3;
      }
      .stripe-card--payment-error-message {
        grid-column: 1 / 7;
        grid-row: 4;
        color: red;
        font-family: Arial, Helvetica, sans-serif;
      }

      // Stripe styling classes

      div.stripe-card--input-styling {
        border-radius: 3px;
        border: 1px solid rgba(175, 188, 198, 0.55);
        padding: auto 0px auto 16px;
        height: 50px;
      }

      div.stripe-card--spacing-wrapper {
        margin: 15px 0px 15px 16px;
      }
    }

    input.cardholder-name--input {
      @include input-styles;
    }

    @include screen-md {
      div.stripe__card-element-container {
        width: 100%;

        .stripe-card__cardholder-name-div {
          grid-column: 1 / 7;
          grid-row: 3;
        }
        .stripe-card__expiry {
          grid-column: 1 / 4;
          grid-row: 4;
        }
        .stripe-card__cvc {
          grid-column: 4 / 6;
          grid-row: 4;
        }
        .stripe-card--payment-error-message {
          grid-column: 1 / 7;
          grid-row: 5;
        }
      }
    }
  }
  .checkout-buttons__container {
    display: flex;
    justify-content: center;
    margin: 121px 0 36px;
  }
  .continue-checkout-button:not(:disabled):hover {
    background-color: #2da2b4;
    cursor: pointer;
  }
}

@include screen-md {
  div.shipping-payment__container {
    padding: 32px 0px !important;
    h2 {
      font-size: 16px;
      letter-spacing: -0.3px;
    }
  }
  div.shipping-info__save-button-container {
    font-size: 16px;
    letter-spacing: 0.3px;
  }
}

.StripeElement::placeholder {
  @include input-placeholder;
}
