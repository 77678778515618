@import '../../common/styles/breakpoints';

div.copay-confirmation__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  padding: 0rem 3rem 2rem 3rem;

  h1 {
    font-family: CircularStd;
    font-size: 28px;
    font-weight: 500;
    margin-top: 56px;
    margin-bottom: 13px;
  }

  h5 {
    font-size: 18px;
    color: #000000;
    text-align: center;
    opacity: 100;
    transition: all 0.3s ease-in-out;
  }

  h5.no-current-meds {
    opacity: 0;
  }

  @include screen-md {
    h1 {
      margin: 35px 10px 15px;
    }

    > div {
      padding: 2rem 0px;
      display: flex;
      flex-direction: column;
      // padding: 2rem;
    }

    .shipping-info__container {
      margin-top: 20px !important;
    }
  }

  div.prescription-list__container {
    width: 100%;
    max-width: 1000px;
    padding-top: 0px;

    h5 {
      font-size: 18px;
      color: #000000;
      text-align: center;
      opacity: 100;
      transition: all 0.3s ease-in-out;
      &.no-current-meds {
        opacity: 0;
      }
    }

    .prescription-section__container {
      margin-top: 71px;
    }

    div.prescription-section__container {
      h2 {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 1rem;
        line-height: 1.5;
      }

      p {
        margin-bottom: 1rem;
        line-height: 1.5;
      }

      @include screen-md {
        margin-top: 39px;
        h2,
        p {
          margin: 0px 10px 15px 17px;
          font-size: 20px;
        }
      }
    }
  }
}

.copay-confirmation__container--status {
  display: flex;
  height: calc(100vh - 180px - 474px);
  padding: auto 0px;
  align-items: center;
  text-align: center;
  div {
    position: relative;
    font-size: 24px;
    margin: 0 auto;
  }
  @include screen-md {
    height: calc(100vh - 60px - 172px);
  }
  p {
    padding: 0 30px;
    line-height: 1.4;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  top: -90px;
  right: 15px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 6px;
    border: 17px solid #44bdd0;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #44bdd0 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

div.buttons__container {
  display: flex;
  justify-content: center;
  margin-top: 71px;
  .checkout__back-button-container {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    img {
      width: 33px;
    }
  }
  @include screen-md {
    margin: 35px auto 31px;
    .checkout__back-button-container {
      width: 52px;
      height: 52px;
      img {
        border-radius: 30px;
        width: 15px;
      }
    }
  }
}

div.checkout__certifications-container,
div.checkout__questions-container {
  width: 100%;
}
