:root {
  --multi-section-animation-speed: 350ms;
}

.open-section {
  -webkit-animation: heightin var(--multi-section-animation-speed) linear forwards;
  animation: heightin var(--multi-section-animation-speed) linear forwards;
}

.close-section {
  -webkit-animation: heightout var(--multi-section-animation-speed) linear forwards;
  animation: heightout var(--multi-section-animation-speed) linear forwards;
}

@keyframes heightin {
  0% {
    max-height: 0px;
    opacity: 0;
  }
  100% {
    max-height: 1000px;
    opacity: 1;
  }
}

@keyframes heightout {
  0% {
    max-height: 1000px;
    opacity: 1;
  }
  100% {
    max-height: 0px;
    opacity: 0;
  }
}
