@import 'variables';
@import 'error404Page';
@import 'aboutTruepillPharmacyContainer';
@import 'testimonials';
@import 'breakpoints';

* {
  box-sizing: border-box;
}

body {
  font-family: Poppins;
}

a {
  text-decoration: none;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/*///*/

.dropdown-select__container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.dropdown-select__select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  margin: 10px 0 0 0;
  width: 100%;
  min-width: 120px;
  height: 50px;
  border-radius: 3px;
  border: solid 1px rgba(175, 188, 198, 0.55);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(57, 66, 72, 0.7);
  cursor: pointer;

  &.disabled {
    cursor: initial;
    opacity: 0.8;
  }
  &.error {
    border-color: #e1656f;
    transition: none;
  }
}

.dropdown-select__select--dropdown {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  /*padding: 0 10px;*/
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  /*height: 50px;*/
  border-radius: 3px;
  border: solid 1px #e4eaed;
  background-color: #ffffff;
}

.dropdown-select__select--dropdown.no-max-height {
  max-height: none;
}

.dropdown-select__select--dropdown-text {
  font-size: 16px;
  font-family: CircularStd;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(57, 66, 72, 0.7);
  padding: 10px 15px;
}

.dropdown-select__select--dropdown-text:hover {
  background-color: rgba(64, 84, 103, 0.08);
  cursor: pointer;
}

.dropdown-select__select--custom-quantity {
  padding: 14px 15px 15px 12px;
}

.dropdown-select__select--custom-quantity:hover {
  background-color: rgba(64, 84, 103, 0.08);
  cursor: pointer;
}

.dropdown-select__select--custom-quantity > input {
  color: rgba(14, 22, 45, 0.46);
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.4px;
  height: 38px;
  padding: 9px 0 10px 11.5px;
  width: 100%;
}

.dropdown-triangle {
  opacity: 0.5;
  width: 0;
  height: 0;
  border: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #b2bac5;
}

.select-text {
  flex: 7;
  font-family: CircularStd;
  user-select: none;
  color: rgba(57, 66, 72, 0.5);
}
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #43bdd0;
  background: -moz-linear-gradient(left, #43bdd0 10%, rgba(67, 189, 208, 0) 42%);
  background: -webkit-linear-gradient(left, #43bdd0 10%, rgba(67, 189, 208, 0) 42%);
  background: -o-linear-gradient(left, #43bdd0 10%, rgba(67, 189, 208, 0) 42%);
  background: -ms-linear-gradient(left, #43bdd0 10%, rgba(67, 189, 208, 0) 42%);
  background: linear-gradient(to right, #43bdd0 10%, rgba(67, 189, 208, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #43bdd0;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #f9fbfe;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.toast-message {
  margin-top: 5rem;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@include screen-md {
  /* for MedicationDetialView */
  .dropdown-select__container {
    position: relative;
    width: 100%;
  }

  .dropdown-select__select--dropdown {
    width: inherit;
  }
}

.inactivity-modal {
  z-index: 10;
}
