@import '../styles/breakpoints';

div.medication-faqs__container {
  background-color: #f4fbfc;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: CircularStd;
  padding: 48px 25% 51px;

  > :last-child {
    box-shadow: none !important;
  }

  h2.medication-faqs__header {
    width: 118.9px;
    height: 51px;
    font-family: CircularStd;
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #293134;
    margin-bottom: 34px;
  }

  div.medication-faq-question__container {
    width: 100%;
    padding: 0 21px;
    box-shadow: inset 0 -1px 0 0 rgba(165, 177, 187, 0.25);

    &:hover {
      cursor: pointer;
    }

    div.medication-faq-question__header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3.medication-faq-question__question-header {
        font-family: CircularStd;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.3px;
        color: #293134;
        // min-height: 85px;
        // line-height: 85px;
        line-height: normal;
        padding-right: 0.7rem;
        transition: all 0.1s ease-in-out;
        user-select: none;

        &.expanded {
          // color: #44bdd0;
        }
      }
      .medication-faq-question__question-header-text {
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      div.medication-faq-question__header-arrow {
        content: '';
        width: 12px;
        height: 12px;
        border-right: 4px solid rgba(0, 0, 0, 0.5);
        border-bottom: 4px solid rgba(0, 0, 0, 0.5);
        transform: rotate(45deg);
        transition: all 0.1s ease-in-out;
        opacity: 0.5;

        &.expanded {
          transform: rotate(225deg);
        }
      }
    }

    p.medication-faq-question__answer-text {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: rgba(41, 49, 52, 0.75);
      margin-bottom: 16px;
      padding-right: 2rem;
      user-select: none;
    }
  }
}

/* Intended for mobile views */
@include screen-md {
  div.medication-faqs__container {
    padding: 40px 24px;

    h2.medication-faqs__header {
      font-size: 26px;
      margin-bottom: 27px;
    }

    div.medication-faq-question__container {
      background-color: rgba(241, 244, 247, 0.55);
      border-radius: 10px;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08);
      padding: 23px 19px;
      margin-bottom: 21px;
      transition: all 0.2s ease-in-out;

      &.expanded {
        background-color: #ffffff;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.13);
      }

      div.medication-faq-question__header-container {
        h3.medication-faq-question__question-header {
          line-height: normal;
          margin-bottom: 13px;
        }

        div.medication-faq-question__header-arrow {
          width: 9px;
          height: 9px;
          border-right: 2px solid rgba(0, 0, 0, 0.5);
          border-bottom: 2px solid rgba(0, 0, 0, 0.5);
          z-index: 2;
        }
      }
    }
    .medication-faq-question__question-header-text {
      height: auto !important;
    }
  }
}
