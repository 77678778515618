/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.page-not-found__container {
  display: flex;
  font-size: 28px;
  height: calc(100vh - 180px - 474px);
  justify-content: center;
  min-height: 300px;
  padding-top: 5rem;
  width: 100%; }

@media screen and (max-width: 992px) {
  .page-not-found__container {
    height: calc(100vh - 172px - 60px);
    min-height: 200px; } }

.about-truepill-pharmacy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0; }

.top-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 4rem;
  width: 86.25rem; }

.top-left {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  order: 1; }

.top-right {
  order: 2; }

.icon-item--container {
  display: flex; }

.icon-container {
  display: flex;
  justify-content: center;
  width: 5rem;
  margin-right: 3rem; }

.raft-icon {
  width: 50px;
  height: 50px;
  object-fit: contain; }

.airplane-icon {
  width: 50px;
  height: 41px;
  object-fit: contain; }

.box-icon {
  width: 40px;
  height: 53px;
  object-fit: contain; }

.icon-item--text-title {
  height: 36px;
  font-family: CircularStd;
  font-size: 28px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a3235; }

.icon-item--text-subtitle {
  width: 370px;
  height: 63px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: rgba(40, 48, 51, 0.65); }

.bottom-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 4rem;
  width: 86.25rem;
  margin-top: 8rem; }

.bottom-left {
  order: 3; }

.bottom-right {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  width: 547px;
  order: 4; }

.location-map {
  width: 669px;
  object-fit: contain; }

.more-info-item--text-title {
  width: 357.7px;
  height: 28px;
  font-family: CircularStd;
  font-size: 22px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a3235; }

.more-info-item--text-subtitle {
  width: 547px;
  height: 54px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: rgba(40, 48, 51, 0.65); }

@media (max-width: 1380px) {
  .about-truepill-pharmacy-container {
    justify-content: center;
    width: 100vw;
    overflow: hidden; }
  .top-section-container {
    flex-direction: column;
    align-items: center; }
  .top-left {
    order: 2;
    margin-top: 4rem; }
  .top-right {
    order: 1; }
    .top-right img {
      width: 400px; }
  .bottom-left {
    order: 3; }
  .bottom-right {
    order: 4;
    width: 100%;
    align-items: center;
    justify-content: space-evenly; }
  .icon-item--text-title {
    font-size: 18px; }
  .icon-item--text-subtitle {
    font-size: 18px; }
  .top-section-container {
    flex-direction: column;
    width: 100%; }
  .bottom-section-container {
    flex-direction: column;
    width: 50rem; }
  .about-truepill-pharmacy-container {
    height: 120rem;
    width: auto; }
  .location-map {
    max-width: 90vw; } }

@media screen and (max-width: 992px) {
  .about-truepill-pharmacy-container {
    width: 100vw; }
  .top-section-container {
    width: auto;
    padding: 0 5%; }
    .top-section-container .icon-item--text-title {
      font-size: 18px; }
    .top-section-container .icon-item--text-subtitle {
      width: 80%; }
  .bottom-section-container {
    width: auto;
    padding: 0 4%; }
  .bottom-section-container {
    width: 100vw;
    align-items: center; }
    .bottom-section-container .icon-item--text-title {
      padding: 0 2rem;
      font-size: 22px; }
    .bottom-section-container .icon-item--text-subtitle {
      font-size: 18px;
      padding: 0 2rem;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: rgba(40, 48, 51, 0.65);
      margin-left: 10%;
      margin-right: 10%;
      width: 200px; }
  .bottom-right {
    align-items: center;
    justify-content: space-evenly;
    width: 100%; } }

@media screen and (max-width: 768px) {
  .about-truepill-pharmacy-container {
    height: auto; }
  .location-map {
    max-width: 400px; }
  .top-section-container {
    width: 100%; }
  .top-section-container {
    flex-direction: column;
    width: 100%; }
    .top-section-container .icon-item--text-container {
      width: 100%;
      height: 50px; }
  .top-right {
    order: 1; }
    .top-right img {
      max-width: 400px; }
  .bottom-section-container {
    flex-direction: column;
    width: 100%; }
  .bottom-section-container {
    width: 100vw; }
    .bottom-section-container img {
      width: 90vw; }
    .bottom-section-container .icon-item--text-title {
      font-size: 22px;
      height: 60px;
      margin: 1rem 10%; }
    .bottom-section-container .icon-item--text-subtitle {
      font-size: 18px;
      padding: 0 2rem;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: rgba(40, 48, 51, 0.65);
      width: 90%; }
  .bottom-right {
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    overflow: hidden; }
    .bottom-right .more-info-item--text-title {
      width: 100%; }
    .bottom-right .more-info-item--text-subtitle {
      width: 100%; } }

.testimonials__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  padding: 4rem 0;
  background-color: rgba(68, 189, 208, 0.05); }

.testimonials__header {
  width: 241px;
  font-family: CircularStd;
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #293134; }

.testimonials__quote {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(41, 49, 52, 0.65);
  max-width: 30rem; }

.testimonials__name-location {
  width: 271px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.86;
  letter-spacing: 0.2px;
  text-align: center;
  color: #293134; }

.testimonial__container {
  display: flex;
  margin-top: 2rem;
  max-width: 40.625rem; }

.testimonial__icon {
  height: 124px;
  width: 124px;
  margin-right: 3rem; }

@media screen and (max-width: 992px) {
  .testimonial__icon {
    align-self: center;
    margin: 0; }
  .testimonials__quote-container {
    margin-top: 2rem; }
  .testimonial__container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100vw;
    padding: 0 15%; } }

* {
  box-sizing: border-box; }

body {
  font-family: Poppins; }

a {
  text-decoration: none; }

.center {
  display: flex;
  flex-direction: row;
  justify-content: center; }

/*///*/
.dropdown-select__container {
  display: inline-block;
  position: relative;
  width: 100%; }

.dropdown-select__select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  margin: 10px 0 0 0;
  width: 100%;
  min-width: 120px;
  height: 50px;
  border-radius: 3px;
  border: solid 1px rgba(175, 188, 198, 0.55);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(57, 66, 72, 0.7);
  cursor: pointer; }
  .dropdown-select__select.disabled {
    cursor: initial;
    opacity: 0.8; }
  .dropdown-select__select.error {
    border-color: #e1656f;
    transition: none; }

.dropdown-select__select--dropdown {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  /*padding: 0 10px;*/
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  /*height: 50px;*/
  border-radius: 3px;
  border: solid 1px #e4eaed;
  background-color: #ffffff; }

.dropdown-select__select--dropdown.no-max-height {
  max-height: none; }

.dropdown-select__select--dropdown-text {
  font-size: 16px;
  font-family: CircularStd;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(57, 66, 72, 0.7);
  padding: 10px 15px; }

.dropdown-select__select--dropdown-text:hover {
  background-color: rgba(64, 84, 103, 0.08);
  cursor: pointer; }

.dropdown-select__select--custom-quantity {
  padding: 14px 15px 15px 12px; }

.dropdown-select__select--custom-quantity:hover {
  background-color: rgba(64, 84, 103, 0.08);
  cursor: pointer; }

.dropdown-select__select--custom-quantity > input {
  color: rgba(14, 22, 45, 0.46);
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.4px;
  height: 38px;
  padding: 9px 0 10px 11.5px;
  width: 100%; }

.dropdown-triangle {
  opacity: 0.5;
  width: 0;
  height: 0;
  border: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #b2bac5; }

.select-text {
  flex: 7 1;
  font-family: CircularStd;
  -webkit-user-select: none;
          user-select: none;
  color: rgba(57, 66, 72, 0.5); }

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #43bdd0;
  background: linear-gradient(to right, #43bdd0 10%, rgba(67, 189, 208, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0); }

.loader:before {
  width: 50%;
  height: 50%;
  background: #43bdd0;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.loader:after {
  background: #f9fbfe;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.toast-message {
  margin-top: 5rem; }

@keyframes load3 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media screen and (max-width: 768px) {
  /* for MedicationDetialView */
  .dropdown-select__container {
    position: relative;
    width: 100%; }
  .dropdown-select__select--dropdown {
    width: inherit; } }

.inactivity-modal {
  z-index: 10; }

div.medication-faqs__container {
  background-color: #f4fbfc;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: CircularStd;
  padding: 48px 25% 51px; }
  div.medication-faqs__container > :last-child {
    box-shadow: none !important; }
  div.medication-faqs__container h2.medication-faqs__header {
    width: 118.9px;
    height: 51px;
    font-family: CircularStd;
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #293134;
    margin-bottom: 34px; }
  div.medication-faqs__container div.medication-faq-question__container {
    width: 100%;
    padding: 0 21px;
    box-shadow: inset 0 -1px 0 0 rgba(165, 177, 187, 0.25); }
    div.medication-faqs__container div.medication-faq-question__container:hover {
      cursor: pointer; }
    div.medication-faqs__container div.medication-faq-question__container div.medication-faq-question__header-container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      div.medication-faqs__container div.medication-faq-question__container div.medication-faq-question__header-container h3.medication-faq-question__question-header {
        font-family: CircularStd;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.3px;
        color: #293134;
        line-height: normal;
        padding-right: 0.7rem;
        transition: all 0.1s ease-in-out;
        -webkit-user-select: none;
                user-select: none; }
      div.medication-faqs__container div.medication-faq-question__container div.medication-faq-question__header-container .medication-faq-question__question-header-text {
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center; }
      div.medication-faqs__container div.medication-faq-question__container div.medication-faq-question__header-container div.medication-faq-question__header-arrow {
        content: '';
        width: 12px;
        height: 12px;
        border-right: 4px solid rgba(0, 0, 0, 0.5);
        border-bottom: 4px solid rgba(0, 0, 0, 0.5);
        transform: rotate(45deg);
        transition: all 0.1s ease-in-out;
        opacity: 0.5; }
        div.medication-faqs__container div.medication-faq-question__container div.medication-faq-question__header-container div.medication-faq-question__header-arrow.expanded {
          transform: rotate(225deg); }
    div.medication-faqs__container div.medication-faq-question__container p.medication-faq-question__answer-text {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: rgba(41, 49, 52, 0.75);
      margin-bottom: 16px;
      padding-right: 2rem;
      -webkit-user-select: none;
              user-select: none; }

/* Intended for mobile views */
@media screen and (max-width: 768px) {
  div.medication-faqs__container {
    padding: 40px 24px; }
    div.medication-faqs__container h2.medication-faqs__header {
      font-size: 26px;
      margin-bottom: 27px; }
    div.medication-faqs__container div.medication-faq-question__container {
      background-color: rgba(241, 244, 247, 0.55);
      border-radius: 10px;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08);
      padding: 23px 19px;
      margin-bottom: 21px;
      transition: all 0.2s ease-in-out; }
      div.medication-faqs__container div.medication-faq-question__container.expanded {
        background-color: #ffffff;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 rgba(0, 0, 0, 0.13); }
      div.medication-faqs__container div.medication-faq-question__container div.medication-faq-question__header-container h3.medication-faq-question__question-header {
        line-height: normal;
        margin-bottom: 13px; }
      div.medication-faqs__container div.medication-faq-question__container div.medication-faq-question__header-container div.medication-faq-question__header-arrow {
        width: 9px;
        height: 9px;
        border-right: 2px solid rgba(0, 0, 0, 0.5);
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        z-index: 2; }
    div.medication-faqs__container .medication-faq-question__question-header-text {
      height: auto !important; } }

.medications-list__container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 10%; }

.medications-list__loading-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 327px); }

.medications-list__loading-text {
  font-family: CircularStd;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: -0.87px; }

.medications-list__loading-text:before,
.medications-list__loading-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px; }

@media screen and (max-width: 768px) {
  div.medications-list__loading-placeholder h1.medications-list__loading-text {
    font-size: 1.8rem; } }

@keyframes ellipsis {
  to {
    width: 1.25em; } }

.medications-list__card {
  width: 275px;
  height: 275px;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(228, 234, 237, 0.5), inset 0 -1px 1px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #e4eaed;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 30px;
  transition: 0.5s; }

.medications-list__card:hover {
  margin-top: 10px;
  transition: 0.5s; }

.medications-list__alphabetical-divider {
  display: none; }

.medications-list__card--medimg-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%; }

.medications-list__card--medimg {
  max-width: 273px;
  max-height: 200px;
  height: auto;
  width: auto; }

.medications-list__card--medname {
  width: 275px;
  height: 60px;
  border-width: 1px 0 0 0;
  border-color: #e4eaed;
  border-style: solid;
  background-color: #f5f8fa;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center; }

.medications-list__card--text {
  font-family: CircularStd;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #252525; }

.medications-list__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0 40px 0; }

.medicaitons-list__heading--1 {
  font-size: 42px;
  line-height: 48px;
  font-family: CircularStd;
  letter-spacing: -0.87px;
  font-weight: 500;
  color: #2f2f2f; }

.medicaitons-list__heading--2 {
  font-family: CircularStd;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #697480; }

/*//////*/
.medication-show__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column; }

.medication-show__drug-display-form {
  margin: 0 calc(100vw * 0.2132); }

.medication-show__drug-display-form--children {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 60px 0; }

.medication-show__drug-display-form--form {
  max-width: 285px; }

.medication-show__drug-display-form--children > .medication-show__drug-display-form--img {
  /*background-color: grey;*/
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 315px;
  max-height: 315px;
  width: auto;
  height: auto;
  margin-right: calc(100vw * 0.075); }

.medication-show__drug-details {
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  width: 68vw;
  margin: 50px auto 10px auto; }

.medication-show__drug-details > .medication-show__quicklinks {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  height: auto;
  max-height: 675px;
  border-radius: 5px;
  background-color: rgba(241, 244, 247, 0.55);
  padding: 22px 34px 29px 35px;
  margin-right: 41px;
  margin-bottom: 10px; }

.medication-show__quicklinks > h4 {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: 400;
  color: #afbcc6;
  line-height: 38px;
  letter-spacing: 0.2px; }

.medication-show__quicklinks > nav.medication-show__quicklinks-options {
  display: flex;
  flex-direction: column;
  font-family: CircularStd;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 2.13;
  letter-spacing: 0.2px; }

.medication-show__quicklinks > nav.medication-show__quicklinks-options > a {
  color: #4a4a4a; }

.medication-show__quicklinks > nav.medication-show__quicklinks-options > a:hover {
  color: #44bdd0;
  cursor: pointer; }

.medication-show__select-group {
  margin: 35px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column; }

.medicaation-show__month-select {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px 0; }

.medicaation-show__month-select--bubble-selected {
  width: 87px;
  height: 40px;
  border-radius: 20px;
  background-color: #afbcc6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.medicaation-show__month-select--bubble {
  width: 87px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #e4eaed;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s; }

.medicaation-show__month-select--bubble:hover {
  width: 87px;
  height: 40px;
  border-radius: 20px;
  background-color: #afbcc6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.medication-show__price {
  font-size: 32px;
  font-weight: 500;
  line-height: 41px;
  text-align: center;
  color: #2f2f2f; }

span.medication-show__calc-price {
  font-size: 40px;
  color: #44bdd0;
  font-weight: 500;
  line-height: 51px; }

.medication-show__buy-button {
  width: 282px;
  height: 60px;
  border-radius: 29.9px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.03);
  background-color: #44bdd0;
  border: none;
  outline: none;
  font-size: 26px;
  font-family: CircularStd;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.15s ease-in-out; }
  .medication-show__buy-button.disabled {
    background-color: #d3d3d3;
    color: #838383;
    opacity: 0.8; }

.medication-show__buy-button:not(.disabled):hover {
  background-color: #2da2b4;
  cursor: pointer; }

.medication-show__disclaimer {
  font-size: 12px;
  font-family: Helvetica;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: 0.25px;
  text-align: center;
  color: #000000;
  opacity: 0.3; }

.medication-show__details-heading {
  font-size: 28px;
  font-family: CircularStd;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 9px; }

.medication-show__details-body {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.7;
  color: #474747; }

.medication-show__details-section {
  margin-bottom: 60px; }

.medication-show__details-section-wrapper {
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 650px; }

.medication-show__details-section-wrapper.expanded {
  max-height: none; }

.medication-show__details-section-container--fade {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.75) 50%, white 70%);
  height: 280px;
  margin-top: -280px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 40px; }

.medication-show__details-section-container--fade.hide {
  display: none; }

.medication-show__details-section-container--fade > .medication-show__show-more-button {
  width: 125px;
  height: 41px;
  border-radius: 20.5px;
  background-color: #afbcc6;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
  margin-bottom: -20px;
  transition: all 250ms linear; }

.medication-show__details-section-container--fade > .medication-show__show-more-button:hover {
  background-color: #44bdd0; }

.medication-show__drug-details--mobile {
  display: none; }

/* Intended for mobile viewports */
@media screen and (max-width: 768px) {
  /* .medications-list__header {
    margin-left: 40px;
  } */
  .medication-show__details-section {
    margin-bottom: 0; }
  .medications-list__header > * {
    text-align: center; }
  .medications-list__container > a {
    width: 100%; }
  .medications-list__card {
    flex-direction: row;
    height: auto;
    width: 100%;
    margin: 0; }
  .medications-list__card--medname {
    background-color: #fff;
    border-width: 0;
    box-shadow: 3px 3px 7px 0 rgba(226, 232, 235, 0.42);
    justify-content: flex-start;
    padding-left: 19px;
    width: 100%; }
  .medications-list__card--medimg-container {
    display: none; }
  .medications-list__alphabetical-divider {
    display: flex;
    margin: 25px 0 0 1.5px; }
  .medications-list__alphabetical-divider:first-of-type {
    margin-top: 0; }
  .medications-list__alphabetical-divider > .medications-list__alphabetical-divider--text {
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f2f2f; }
  /* for MedicationDetialView */
  .medication-show__drug-display-form {
    margin: 0 46px 0 46px; }
  .medication-show__drug-display-form--form {
    font-family: CircularStd;
    max-width: 77vw;
    display: flex;
    flex-direction: column; }
  .medication-show__drug-display-form--img {
    height: auto !important;
    width: auto !important;
    max-height: calc(100vw * 0.2) !important;
    max-width: calc(100vw * 0.76) !important;
    margin-right: 0 !important; }
  .medication-show__drug-display-form--children {
    flex-direction: column;
    background-color: #fff !important;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 0; }
  .medicaitons-list__heading--1 {
    font-size: 32px;
    line-height: 1;
    letter-spacing: -0.7px;
    text-align: center;
    margin: 19px 0 8px 0; }
  span.medication-show__calc-price {
    text-align: center;
    font-size: 26px;
    line-height: 33px; }
  .medication-show__select-group {
    margin-top: 19px; }
  .medication-show__drug-details {
    display: none; }
  .medication-show__drug-details--mobile {
    display: flex; }
  .medication-show__checkout-group {
    display: flex;
    width: 77vw;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .medication-show__select-group {
    width: 100%; }
  .medication-show__drug-details--mobile-about {
    margin: 34px 15px 41px 21px; }
  .medication-show__details-divider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #ececec;
    font-family: CircularStd;
    font-size: 18px;
    font-weight: 450;
    line-height: 1.85;
    padding-left: 21px;
    padding-right: 19px;
    margin-bottom: 0;
    height: 46px; }
  .medication-show__details-divider:hover {
    cursor: pointer; }
  .medication-show__details-section-text {
    font-family: HelveticaNeue;
    padding: 20px 21px 35px 21px;
    border-bottom: solid 1px #ececec;
    font-size: 14px;
    color: #474747;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 0;
    opacity: 0.7; }
  .medication-show__details-section > div:nth-of-type(2) {
    border-top: solid 1px #ececec; } }

.goodrx-banner__container {
  background-color: #ffee59;
  padding: 0 5rem;
  position: absolute;
  width: 100vw;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: CircularStd;
  color: #544f1d;
  font-size: 21px;
  text-align: center; }
  .goodrx-banner__container.hide {
    display: none; }
  .goodrx-banner__container img.goodrx-banner__logo-img {
    height: 21px;
    padding-left: 0.45rem;
    vertical-align: middle; }
  .goodrx-banner__container div.goodrx-banner__close-button {
    justify-self: flex-end;
    font-size: 15px;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer; }

@media screen and (max-width: 768px) {
  .goodrx-banner__container {
    font-size: 16px;
    height: 78px;
    padding: 0 1rem;
    line-height: 1.5; }
    .goodrx-banner__container span.goodrx-banner__text {
      margin-left: 0;
      margin-right: 10px; }
    .goodrx-banner__container img.goodrx-banner__logo-img {
      height: 16px;
      padding-left: 0.4rem; }
    .goodrx-banner__container div.goodrx-banner__close-button {
      margin-right: 8px; } }

.chkoutTransferMedicationsMultiline {
  background-color: #fff; }

div.order-complete__container {
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: CircularStd;
  padding: 53.3px 25% 58px; }
  div.order-complete__container img.order-complete__checkmark-img {
    max-width: 64px;
    max-height: 64px;
    margin-bottom: 34.4px; }
  div.order-complete__container h2.order-complete__heading {
    font-size: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    font-weight: 500;
    line-height: 1.6;
    text-align: center; }
  div.order-complete__container h3.order-complete__subheading {
    width: 382px;
    font-family: CircularStd;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-top: 1rem; }
  div.order-complete__container p.order-complete__text-top {
    font-family: CircularStd;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #394248;
    margin-top: 2rem; }
  div.order-complete__container p.order-complete__text-bottom {
    font-family: CircularStd;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #394248;
    margin-top: 1rem; }
  div.order-complete__container p.order-complete__text {
    font-family: CircularStd;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #394248;
    margin-top: 3rem; }
  div.order-complete__container input.order-complete__password-input {
    border: 1px solid #d7d7d7;
    height: 50px;
    width: 315px;
    border-radius: 3px;
    padding-left: 1rem;
    outline: none;
    margin-bottom: 36px; }
    div.order-complete__container input.order-complete__password-input::placeholder {
      font-family: HelveticaNeue;
      font-size: 16px;
      color: rgba(77, 77, 77, 0.43); }

@media screen and (max-width: 768px) {
  div.order-complete__container {
    padding: 42px 8% 33px; }
  .order-complete__subheading {
    width: 340px !important;
    font-size: 26px !important; } }

.support-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 226px;
  height: 46px;
  border-radius: 55px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
  background-color: #4bbdcf;
  margin-top: 2rem; }

.support-button__text {
  width: 136px;
  height: 23px;
  font-family: CircularStd;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff; }

@charset "UTF-8";
.checkout__form--heading {
  font-family: CircularStd;
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  color: #000000; }

.checkout__intro-text {
  font-family: CircularStd;
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #44bdd0; }

.checkout__steptracker {
  max-width: 798px; }

.checkout__heading-container {
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: space-evenly; }

@media screen and (max-width: 992px) {
  .checkout__heading-container {
    display: flex;
    flex-direction: column;
    height: 8rem;
    justify-content: space-evenly; }
  .checkout__form--heading {
    font-size: 25px; }
  .checkout__intro-text {
    font-size: 16px; } }

.checkout__container {
  width: 800px;
  margin: 45px auto 20px auto;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  box-shadow: 0 3px 7px 1px rgba(224, 224, 224, 0.5); }
  .checkout__container .checkout__form--container {
    display: flex;
    flex-direction: column;
    flex: 5.5 1;
    background-color: #fbfbfb;
    padding: 35px 50px;
    position: relative;
    box-shadow: 0 3px 7px 1px rgba(224, 224, 224, 0.5);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
    .checkout__container .checkout__form--container h3.checkout__form--heading {
      font-family: CircularStd;
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.67px;
      text-align: left;
      color: #2f2f2f;
      margin-bottom: 5px; }
    .checkout__container .checkout__form--container h2.checkout__form--heading {
      font-family: CircularStd;
      font-style: normal;
      font-weight: bold;
      font-stretch: normal;
      line-height: normal;
      text-align: left;
      color: #2f2f2f;
      margin-bottom: 5px;
      letter-spacing: -0.4px; }
    .checkout__container .checkout__form--container .checkout__intro-text {
      opacity: 0.41;
      font-family: CircularStd;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 25px; }
    .checkout__container .checkout__form--container .MuiTextField-root {
      margin-top: 1rem; }
    .checkout__container .checkout__form--container .MuiInputBase-input {
      border-radius: 3px;
      border: solid 1px rgba(175, 188, 198, 0.55);
      background-color: #ffffff; }
      .checkout__container .checkout__form--container .MuiInputBase-input.MuiOutlinedInput-input {
        border: none;
        /* This makes disabled inputs more visually distinct. */ }
        .checkout__container .checkout__form--container .MuiInputBase-input.MuiOutlinedInput-input:disabled {
          background-color: #eee;
          color: rgba(0, 0, 0, 0.87);
          cursor: not-allowed; }
    .checkout__container .checkout__form--container .MuiFormLabel-root {
      font-family: CircularStd;
      color: rgba(57, 66, 72, 0.5); }
    .checkout__container .checkout__form--container .checkout__survey-fields-container {
      margin-top: 2.6rem; }
    .checkout__container .checkout__form--container .checkout__form--input {
      width: 100%;
      height: 50px;
      border-radius: 3px;
      border: solid 1px rgba(175, 188, 198, 0.55);
      background-color: #ffffff;
      font-size: 16px;
      font-family: CircularStd;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: left;
      color: #000000;
      margin: 10px 0;
      padding: 0 1rem; }
      .checkout__container .checkout__form--container .checkout__form--input::placeholder {
        font-family: CircularStd;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: left;
        color: rgba(57, 66, 72, 0.5); }
      .checkout__container .checkout__form--container .checkout__form--input.error {
        border-color: #e1656f; }
    .checkout__container .checkout__form--container .checkout__toggle-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
      .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container {
        display: flex;
        flex-direction: row; }
        .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container > :first-child {
          margin-right: 15px; }
        .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container .checkout__radio-input-option {
          display: flex;
          flex-direction: row; }
          .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container .checkout__radio-input-option label {
            margin-right: 2px; }
    .checkout__container .checkout__form--container .checkout__form--textarea {
      resize: none;
      width: 100%;
      height: 90px;
      border: 1px solid rgba(175, 188, 198, 0.55);
      border-radius: 3px;
      font-size: 16px;
      font-family: CircularStd;
      padding: 1rem;
      margin-bottom: 37px; }
      .checkout__container .checkout__form--container .checkout__form--textarea::placeholder {
        font-family: CircularStd;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: left;
        color: rgba(57, 66, 72, 0.5); }
      .checkout__container .checkout__form--container .checkout__form--textarea.error {
        border: 1px solid red; }
    .checkout__container .checkout__form--container .checkout__label-row {
      display: flex;
      align-items: center; }
      .checkout__container .checkout__form--container .checkout__label-row.patient-customer-checkbox {
        padding: 0 0.5rem; }
        .checkout__container .checkout__form--container .checkout__label-row.patient-customer-checkbox label.checkout__flow--input-label {
          margin-right: 0.5rem; }
      .checkout__container .checkout__form--container .checkout__label-row span.field-required__indicator {
        margin-left: 0.5rem; }
      .checkout__container .checkout__form--container .checkout__label-row span.field-required__indicator,
      .checkout__container .checkout__form--container .checkout__label-row span.field-required__indicator-text {
        font-family: CircularStd;
        font-size: 13px;
        font-weight: 300;
        color: #e1656f; }
    .checkout__container .checkout__form--container .checkout__flow--input-label {
      font-size: 16px;
      font-family: CircularStd;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: rgba(57, 66, 72, 0.5); }
    .checkout__container .checkout__form--container .checkout__form-row {
      margin: 8px 0; }
      .checkout__container .checkout__form--container .checkout__form-row.card-security {
        display: flex;
        flex-direction: row; }
        .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--expiration {
          margin-right: 10px; }
          .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--expiration .checkout__flow--card-expiration {
            display: flex;
            flex-direction: row;
            width: 100%;
            min-width: 250px;
            justify-content: space-between; }
            .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--expiration .checkout__flow--card-expiration div:first-of-type {
              margin-right: 10px; }
        .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--cvc {
          max-width: 90px; }
    .checkout__container .checkout__form--container .checkout__city-state-row {
      display: flex;
      justify-content: space-between; }
      .checkout__container .checkout__form--container .checkout__city-state-row input.checkout__form--input {
        width: 50%; }
    .checkout__container .checkout__form--container .checkout__dob-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0;
      margin-bottom: 29px; }
      .checkout__container .checkout__form--container .checkout__dob-row .checkout__dob-row--form-input {
        display: flex;
        flex-direction: row;
        max-width: 100%; }
        .checkout__container .checkout__form--container .checkout__dob-row .checkout__dob-row--form-input > * {
          margin: 0 5px 10px 0; }
      .checkout__container .checkout__form--container .checkout__dob-row label {
        margin-left: 5px; }
    .checkout__container .checkout__form--container .checkout__form--footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
      .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group {
        margin: 51.6px 0px 28px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%; }
        .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group .checkout__form--next-button {
          width: 333px;
          border-radius: 30px;
          box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
          background-color: #44bdd0;
          height: 60px;
          outline: none;
          border: none;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease-in; }
          .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group .checkout__form--next-button:hover {
            background-color: #2da2b4; }
        .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group .checkout__form--button-text {
          font-size: 22px;
          font-family: CircularStd;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          -webkit-user-select: none;
                  user-select: none; }
    .checkout__container .checkout__form--container p.checkout__question-text {
      font-family: CircularStd;
      font-size: 20px;
      color: rgba(57, 66, 72, 0.65);
      letter-spacing: 0.25px;
      margin-top: 51px;
      text-align: center; }
    .checkout__container .checkout__form--container div.checkout__question-button-container {
      margin-top: 31px; }
      .checkout__container .checkout__form--container div.checkout__question-button-container > * {
        margin-right: 21px; }
      .checkout__container .checkout__form--container div.checkout__question-button-container > :last-child {
        margin-right: 0; }
      .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button {
        width: 127px;
        height: 52px;
        border-radius: 26px;
        font-family: CircularStd;
        font-size: 20px;
        color: #ffffff;
        outline: none;
        border: none; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.yes {
          background-color: #44bdd0; }
          .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.yes:hover {
            background-color: #2da2b4; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.no, .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.back {
          background-color: #afbcc6; }
          .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.no:hover, .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.back:hover {
            background-color: #91a3b1; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.back {
          border-radius: 30px;
          height: 60px;
          width: 60px; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button:hover {
          cursor: pointer; }
    .checkout__container .checkout__form--container .checkout__prescription-required-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 70px;
      line-height: 1.3;
      text-align: center; }
      .checkout__container .checkout__form--container .checkout__prescription-required-container h2.checkout__prescription-required-text {
        font-family: CircularStd;
        font-weight: 500;
        font-size: 28px;
        color: #000000;
        margin-bottom: 20px; }
      .checkout__container .checkout__form--container .checkout__prescription-required-container p {
        font-family: CircularStd;
        font-size: 1.1rem; }
    .checkout__container .checkout__form--container .checkout__transfer-all-rx-container {
      display: flex;
      margin: 10px 0; }
      .checkout__container .checkout__form--container .checkout__transfer-all-rx-container .checkout__radio-button-container {
        margin-left: 25px; }
        .checkout__container .checkout__form--container .checkout__transfer-all-rx-container .checkout__radio-button-container input {
          margin-right: 12px; }
        .checkout__container .checkout__form--container .checkout__transfer-all-rx-container .checkout__radio-button-container label {
          margin-right: 3px; }
  .checkout__container .checkout__purchase-bill--container {
    flex: 3 1;
    border-radius: inherit;
    background-color: #ffffff;
    padding: 20px 60px; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--img-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px; }
      .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--img-container .checkout__purchase-bill--img {
        display: block;
        height: auto;
        width: auto;
        max-height: 110px;
        max-width: 110px; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--details-container .checkout__purchase-bill--heading {
      font-family: CircularStd;
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2f2f2f; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--details-container .checkout__purchase-bill--purchase-price-total {
      margin: 25px 0;
      font-family: CircularStd;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--details-container .checkout__purchase-bill--item-details .checkout__purchase-bill--line-item {
      padding: 25px 0;
      font-family: CircularStd;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: rgba(47, 47, 47, 0.6);
      border-bottom-width: 1px;
      border-bottom-style: dashed; }

.order-processing__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  text-align: center; }

.order-processing__text {
  font-size: 30px;
  font-family: CircularStd;
  font-weight: 450;
  line-height: 1.7; }

@media screen and (max-width: 768px) {
  .order-processing__text {
    font-size: 18px; } }

@media screen and (max-width: 768px) {
  div.checkout__city-state-row div.dropdown-select__container div.dropdown-select__select--dropdown {
    width: 100%; }
  div.checkout__prescription-required-container {
    margin: 0 5% !important; } }

@media screen and (max-width: 768px) {
  div.checkout__purchase-bill--line-item {
    border-bottom-width: 0px !important; } }

@media screen and (max-width: 768px) {
  /* for MedicationDetialView */
  .checkout__container {
    margin: 0;
    width: 100%;
    /* max-width: 100vw; */
    flex-direction: column;
    border-radius: 0;
    box-shadow: none;
    border: none; }
  .checkout__container > .checkout__form--container {
    border-radius: 0;
    padding: 20px 25px; }
  .checkout__container > .checkout__form--container > .checkout__dob-row > .checkout__dob-row--form-input {
    display: flex;
    flex-direction: column; }
  .checkout__purchase-bill--container {
    display: flex;
    order: -2;
    padding: 0 !important;
    height: 91px !important;
    max-height: 91px !important; }
    .checkout__purchase-bill--container div.checkout__purchase-bill--img-container {
      order: 2;
      margin-bottom: 0 !important;
      padding: 10px; }
      .checkout__purchase-bill--container div.checkout__purchase-bill--img-container img {
        height: 61px !important;
        width: auto !important; }
    .checkout__purchase-bill--container div.checkout__purchase-bill--details-container {
      width: 100%;
      padding-left: 1rem; }
      .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--heading {
        font-size: 20px !important;
        margin-top: 10px; }
      .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 66px; }
        .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details > * {
          font-size: 15px !important;
          color: rgba(57, 66, 72, 0.5) !important; }
        .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--line-item.strength:after, .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--line-item.quantity:after {
          content: '\2022';
          opacity: 0.5;
          padding: 0 0.5rem; }
        .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--purchase-price-total {
          order: 2; }
          .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--purchase-price-total span.checkout__purchase-bill--total-text {
            display: none; }
  .checkout__purchase-bill--line-item-label {
    display: none; }
  .card-security {
    display: flex;
    flex-direction: column !important; }
  .card-security > * {
    margin-bottom: 4px; }
  .checkout__form--footer > .checkout__form-button-group > a.checkout__form--next-button {
    margin-left: 10px; }
  .checkout__form-logo-group {
    margin: 0px 20px; }
  .checkout__form-logo-group > .checkout__form-logo {
    max-width: calc(20vw - 20px); } }

.step-tracker {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  flex-direction: row !important; }
  .step-tracker .step-tracker__step-container {
    flex: 1 1 32.8%;
    background-color: #fbfbfb;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #44bdd0;
    font-family: CircularStd;
    font-size: 16px;
    transition: box-shadow 0.2s linear;
    box-shadow: inset 0 -2px 0 -1px #a5a9ab;
    margin-left: 7px; }
    .step-tracker .step-tracker__step-container.active {
      box-shadow: inset 0 -1px 0 0 #44bdd0; }
  .step-tracker .step-tracker__step-container:first-child {
    border-radius: 10px 0 0 10px;
    margin-left: 0; }
  .step-tracker .step-tracker__step-container:last-child {
    border-radius: 0 10px 10px 0; }
  @media screen and (max-width: 768px) {
    .step-tracker .step-tracker__step-container {
      height: 5px;
      border-radius: 0;
      box-shadow: none;
      background-color: #c6eaf1; }
      .step-tracker .step-tracker__step-container p {
        display: none; }
    .step-tracker .active {
      background-color: #44bdd0; } }

.checkout__questions-container {
  background-color: #f8fafd;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  padding: 53px 0; }
  .checkout__questions-container h3.checkout__questions-header {
    font-family: CircularStd;
    font-size: 32px;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f2f2f; }
  .checkout__questions-container p.checkout__questions-text {
    font-family: CircularStd;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(47, 47, 47, 0.65); }

.checkout__certifications-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  padding: 82px 0 59px; }
  .checkout__certifications-container p.checkout__certifications-text {
    font-family: CircularStd;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(47, 47, 47, 0.35); }
  .checkout__certifications-container .checkout__certifications-logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%; }
    .checkout__certifications-container .checkout__certifications-logo-container img {
      margin-top: 36px;
      max-height: 57px;
      max-width: 20%; }

@media screen and (max-width: 768px) {
  .checkout__questions-container {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-top: 27px; }
    .checkout__questions-container h3.checkout__questions-header {
      font-size: 26px; }
    .checkout__questions-container p.checkout__questions-text {
      font-size: 15px; } }

@media screen and (max-width: 768px) {
  .checkout__certifications-container {
    padding: 29px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06); }
    .checkout__certifications-container p.checkout__certifications-text {
      display: none;
      padding: 3rem 5% 2rem;
      text-align: center; }
    .checkout__certifications-container .checkout__certifications-logo-container {
      width: 100%;
      justify-content: space-around; }
      .checkout__certifications-container .checkout__certifications-logo-container img {
        max-height: 34px;
        margin-top: 0; } }

@media screen and (max-width: 768px) {
  .checkout__flow--input-label {
    margin-right: 2rem; } }

@media screen and (max-width: 768px) {
  div.checkout__label-row label.checkout__flow--input-label {
    margin-right: 0 !important; }
  div.checkout__label-row span.field-required__indicator-text {
    display: none; } }

.dropdown-select__state {
  width: 30%;
  align-self: center; }

.dropdown-select__state__control {
  height: 48px; }

.dropdown-select__state__placeholder {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(57, 66, 72, 0.5); }

.dropdown-select__shipping__control {
  margin-top: 10px; }

.dropdown-select__shipping__control {
  height: 48px; }

.dropdown-select__shipping__placeholder {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(57, 66, 72, 0.5); }

.dropdown-select__shipping-error__container {
  border-color: #e1656f !important; }

.dropdown-select__shipping-error__control {
  height: 48px; }

.dropdown-select__shipping-error__placeholder {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(57, 66, 72, 0.5); }

.prescription-info_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 100%;
  align-self: center;
  justify-content: center; }

.RectangleTop {
  width: 1440px;
  height: 322px;
  opacity: 0.05;
  background-image: linear-gradient(to bottom, #44bdd0, rgba(0, 151, 175, 0.8)), linear-gradient(to bottom, #fafbfb, #fafbfb); }

@media screen and (max-width: 768px) {
  .checkout__form--next-button {
    width: 225px !important; }
  .checkout__question-text {
    width: 100vw;
    text-align: center;
    line-height: 1.4; }
  .checkout__question-button-container {
    display: flex; } }

.additional-info__header {
  font-family: CircularStd;
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }

@media (max-width: 374px) {
  .checkout__form--next-button {
    width: 175px !important; } }

.footer__container {
  font-family: Roboto;
  height: 100%;
  min-height: 190px;
  background-color: #44bdd0;
  padding: 100px 19.86vw 50px 19.86vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 100px; */ }
  .footer__container .footer__main {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 40px; }
    .footer__container .footer__main .footer__company-section {
      font-family: CircularStd;
      margin-right: 49px; }
      .footer__container .footer__main .footer__company-section .footer__logo {
        margin-bottom: 24px; }
        .footer__container .footer__main .footer__company-section .footer__logo img.footer__logo--img {
          width: 85px;
          height: 33px;
          opacity: 0.5; }
      .footer__container .footer__main .footer__company-section .footer__contact-info {
        display: flex;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.65);
        font-size: 15px;
        letter-spacing: 0.25px;
        line-height: 23px;
        width: 170px;
        height: auto;
        justify-content: space-between;
        margin-bottom: 18px; }
        .footer__container .footer__main .footer__company-section .footer__contact-info span.oval {
          display: none; }
        .footer__container .footer__main .footer__company-section .footer__contact-info a {
          color: rgba(255, 255, 255, 0.65); }
          .footer__container .footer__main .footer__company-section .footer__contact-info a:hover {
            cursor: pointer; }
      .footer__container .footer__main .footer__company-section .footer__copyright-notice {
        grid-area: copyright;
        display: flex;
        flex-direction: column;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 23px;
        letter-spacing: 0.25px;
        color: rgba(255, 255, 255, 0.65); }
    .footer__container .footer__main .footer__divider {
      height: 100%;
      min-height: 190px;
      width: 1px;
      opacity: 0.16;
      background-color: #fff;
      margin-right: 52px; }
    .footer__container .footer__main .footer__support-section--container {
      display: flex;
      justify-content: space-around;
      width: 100%;
      font-family: CircularStd; }
      .footer__container .footer__main .footer__support-section--container .footer__support-section--group {
        font-size: 15px;
        line-height: 26px;
        min-width: 108px;
        max-width: 128px; }
        .footer__container .footer__main .footer__support-section--container .footer__support-section--group h4.footer__support-section--header {
          letter-spacing: 0.25px;
          color: #fff; }
        .footer__container .footer__main .footer__support-section--container .footer__support-section--group ul li.footer__support-section--group-item {
          color: rgba(255, 255, 255, 0.65); }
          .footer__container .footer__main .footer__support-section--container .footer__support-section--group ul li.footer__support-section--group-item:hover {
            color: rgba(255, 255, 255, 0.65);
            text-decoration: underline;
            cursor: pointer; }
          .footer__container .footer__main .footer__support-section--container .footer__support-section--group ul li.footer__support-section--group-item a {
            color: rgba(255, 255, 255, 0.65); }

.footer-mobile__container {
  display: none; }

/* Intended for mobile views */
@media screen and (max-width: 768px) {
  .footer__container {
    display: none; }
  .footer__logo--img {
    width: 56px;
    opacity: 0.5; }
  .footer-mobile__container {
    display: flex;
    flex-direction: column;
    background-color: #44bdd0;
    width: 100%;
    height: 172px;
    font-family: CircularStd;
    font-size: 14px;
    letter-spacing: 0.22px;
    padding: 1.6rem; }
  .footer-mobile__bottom-section {
    display: flex;
    margin-top: 1rem; }
  .mobile-footer__info-container {
    height: 75px;
    width: 40%;
    border-right: 1px solid rgba(255, 255, 255, 0.5); }
    .mobile-footer__info-container p {
      font-family: CircularStd;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0.2px;
      color: rgba(255, 255, 255, 0.65); }
    .mobile-footer__info-container p:nth-of-type(3) {
      margin-top: 0.5rem; }
  .mobile-footer__link-container {
    width: 60%;
    margin-left: 1rem;
    justify-content: space-evenly; }
    .mobile-footer__link-container a {
      font-family: CircularStd;
      font-size: 12px;
      font-weight: 100;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0.2px;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer; }
  .mobile-footer__link-section-company {
    display: flex;
    flex-direction: column; }
  .mobile-footer__link-section-legal {
    display: flex;
    flex-direction: column; }
  h4 {
    font-family: CircularStd;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.75); }
  .mobile-footer__link-container {
    display: flex;
    width: 60%; } }

/* FrequentlyAskedQuestions */
.faq__header {
  margin: 68px 0 49px; }

.faq__header > .faq__heading--1 {
  color: #2f2f2f;
  letter-spacing: -0.25px;
  font-family: CircularStd;
  font-weight: 450;
  font-size: 42px;
  text-align: center; }

h1.faq__heading--1 {
  font-weight: 400; }

.faq__search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 48px; }

.faq__search-bar--input-container {
  height: 61px;
  width: 865px;
  display: flex;
  align-items: center;
  border: 1px solid #e4eaed;
  border-radius: 5px; }

.faq__search-bar--input {
  height: 56px;
  width: 100%;
  border: none;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAbFJREFUKBWVUs0r9FEUPudiGBYoIatXNspWCfkoRaaUxRh2pBhlp4iVnWJNmSmEjZiFku963152mj+AhbKxGQulfJXfPc4jVzdRnPp1z9fznHuf82N6t8TmcaGhYFxYGoWkWoSu2PBZXm5orr+r7dr1+ScjSKQO68jaNSGq0MStpi70q9S4zBDdiTGj8WjHNnp9Y0wkejljlmIWnhqKdS65hsXUfoQsLRiWAgmoPt4XuXQ1nIY4mMBEbZr0gSiORDv3QpQ1YIXDnGVmkPPNMEkDrjrcG1n2C84fjLX/16a0iNS7nDsNxNEAb/zeWM71dsUru//K/SYDVTVR6Sc/+2LNH2Z6LrhvvfFrBuuAqm/i+JV3P5n6WyNk61goHYtx4LcY7BHrgKrLm0ctfhFAK8/rRJyj65r2a/DdnrtZgiRUhTiEN+pVMRFAbbph4nUJl8zGu2ofHMkbGEFiY68K61BAgwgX4Y24KiaylYglGdN/4ZTySqOO4APs2HBCVYjj3pjYSefTUyalpE0+wZdgn8j5PgGRnHC4tOfHYJCAQB4zW6pDs+py8CvwB8FTZjUk2fOv+Tu5GgMO4l4AAAAASUVORK5CYII=) no-repeat scroll 19px 20px;
  font-size: 18px;
  color: #697480;
  opacity: 0.5;
  text-indent: 50px; }

.faq__search-bar--input:focus {
  outline: none; }

/* Questions */
.faq__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  margin: 0 19.25vw; }

.faq__container > * {
  margin-bottom: 25px; }

.faq-group__header {
  color: #afbcc6;
  font-size: 28px;
  font-weight: bold;
  width: 837px; }

.faq-question__container {
  width: 837px;
  min-height: 85px;
  max-height: 85px;
  overflow: hidden;
  background-color: rgba(241, 244, 247, 0.55);
  border-radius: 10px;
  padding: 30px 22px 0;
  transition: all 0.5s ease-in-out; }

.faq-question__container.active {
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  max-height: 9001px; }

.faq-question__header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 26px; }

.faq-question__header-icon.open {
  max-height: 26px;
  max-width: 26px; }

.faq-question__header-icon.closed {
  max-height: 12px;
  max-width: 18px; }

h1.faq-question__title {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
  transition: color 0.25s; }

h1.faq-question__title.active {
  color: #44bdd0; }

.faq-question__body {
  color: #474747;
  font-size: 15px;
  line-height: 23px;
  max-height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out; }

.faq-question__body.active {
  opacity: 1;
  max-height: 10000px;
  padding: 25px 0; }

.faq__heading--no-results {
  font-size: 34px;
  font-weight: bold;
  color: #afbcc6; }

.scaffold-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8.5%;
  font-family: CircularStd;
  padding: 3.5% 0; }

.page-title,
.section-title {
  font-weight: bold;
  letter-spacing: -0.25px;
  color: #1c2b36; }

.page-title {
  font-size: 42px; }

.section-title {
  font-size: 18px;
  line-height: 25px; }

.scaffold__content {
  padding: 2.5% 0;
  width: 100%; }

.scaffold__content > *,
.faq-question__body > * {
  margin-bottom: 12px; }

.p-text,
.scaffold__content > ul {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.25px;
  color: rgba(28, 43, 54, 0.84); }

.bold-text {
  font-weight: bold; }

.underline-text {
  text-decoration: underline; }

.scaffold__content > ul,
.faq-question__body > ul {
  list-style: disc inside none; }

@media (max-width: 1024px) {
  div.faq__search-bar {
    width: 82%;
    margin: auto;
    margin-bottom: 2.5rem; }
  div.faq__container {
    width: 82%;
    margin: auto; }
    div.faq__container div.faq-group__header,
    div.faq__container div.faq-question__container {
      width: 100%;
      max-height: none; }
      div.faq__container div.faq-group__header h1.faq__heading--1,
      div.faq__container div.faq-question__container h1.faq__heading--1 {
        font-weight: 300;
        text-align: center; }
      div.faq__container div.faq-group__header h1.faq-question__title,
      div.faq__container div.faq-question__container h1.faq-question__title {
        margin-right: 1rem;
        font-size: 16px; }
      div.faq__container div.faq-group__header div.faq-question__body p.p-text,
      div.faq__container div.faq-question__container div.faq-question__body p.p-text {
        font-size: 14px; } }

.confirm-order__container {
  width: 100%;
  max-width: 1000px; }
  .confirm-order__container h2 {
    margin-top: 60px;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.5; }
  @media screen and (max-width: 768px) {
    .confirm-order__container h2 {
      margin: 39px 10px 28px 17px;
      font-size: 20px; } }

div.copay-confirmation__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  padding: 0rem 3rem 2rem 3rem; }
  div.copay-confirmation__container h1 {
    font-family: CircularStd;
    font-size: 28px;
    font-weight: 500;
    margin-top: 56px;
    margin-bottom: 13px; }
  div.copay-confirmation__container h5 {
    font-size: 18px;
    color: #000000;
    text-align: center;
    opacity: 100;
    transition: all 0.3s ease-in-out; }
  div.copay-confirmation__container h5.no-current-meds {
    opacity: 0; }
  @media screen and (max-width: 768px) {
    div.copay-confirmation__container h1 {
      margin: 35px 10px 15px; }
    div.copay-confirmation__container > div {
      padding: 2rem 0px;
      display: flex;
      flex-direction: column; }
    div.copay-confirmation__container .shipping-info__container {
      margin-top: 20px !important; } }
  div.copay-confirmation__container div.prescription-list__container {
    width: 100%;
    max-width: 1000px;
    padding-top: 0px; }
    div.copay-confirmation__container div.prescription-list__container h5 {
      font-size: 18px;
      color: #000000;
      text-align: center;
      opacity: 100;
      transition: all 0.3s ease-in-out; }
      div.copay-confirmation__container div.prescription-list__container h5.no-current-meds {
        opacity: 0; }
    div.copay-confirmation__container div.prescription-list__container .prescription-section__container {
      margin-top: 71px; }
    div.copay-confirmation__container div.prescription-list__container div.prescription-section__container h2 {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 1rem;
      line-height: 1.5; }
    div.copay-confirmation__container div.prescription-list__container div.prescription-section__container p {
      margin-bottom: 1rem;
      line-height: 1.5; }
    @media screen and (max-width: 768px) {
      div.copay-confirmation__container div.prescription-list__container div.prescription-section__container {
        margin-top: 39px; }
        div.copay-confirmation__container div.prescription-list__container div.prescription-section__container h2,
        div.copay-confirmation__container div.prescription-list__container div.prescription-section__container p {
          margin: 0px 10px 15px 17px;
          font-size: 20px; } }

.copay-confirmation__container--status {
  display: flex;
  height: calc(100vh - 180px - 474px);
  padding: auto 0px;
  align-items: center;
  text-align: center; }
  .copay-confirmation__container--status div {
    position: relative;
    font-size: 24px;
    margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .copay-confirmation__container--status {
      height: calc(100vh - 60px - 172px); } }
  .copay-confirmation__container--status p {
    padding: 0 30px;
    line-height: 1.4; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  top: -90px;
  right: 15px; }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 6px;
    border: 17px solid #44bdd0;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #44bdd0 transparent transparent transparent; }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s; }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s; }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

div.buttons__container {
  display: flex;
  justify-content: center;
  margin-top: 71px; }
  div.buttons__container .checkout__back-button-container {
    width: 75px;
    height: 75px;
    border-radius: 50%; }
    div.buttons__container .checkout__back-button-container img {
      width: 33px; }
  @media screen and (max-width: 768px) {
    div.buttons__container {
      margin: 35px auto 31px; }
      div.buttons__container .checkout__back-button-container {
        width: 52px;
        height: 52px; }
        div.buttons__container .checkout__back-button-container img {
          border-radius: 30px;
          width: 15px; } }

div.checkout__certifications-container,
div.checkout__questions-container {
  width: 100%; }

.question-icon {
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 350px;
  background: #ffffff;

  position: absolute;
  margin-left: 32px;
  z-index: 1001;
}

.triangle-icon {
  position: absolute;
  margin-left: 0px;
  margin-top: 5px;
  z-index: 1002;
}

.box-shadow-shipping {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2), 0px -2px 10px rgba(24, 39, 75, 0.2);
}

:root {
  --multi-section-animation-speed: 350ms;
}

.open-section {
  animation: heightin var(--multi-section-animation-speed) linear forwards;
}

.close-section {
  animation: heightout var(--multi-section-animation-speed) linear forwards;
}

@keyframes heightin {
  0% {
    max-height: 0px;
    opacity: 0;
  }
  100% {
    max-height: 1000px;
    opacity: 1;
  }
}

@keyframes heightout {
  0% {
    max-height: 1000px;
    opacity: 1;
  }
  100% {
    max-height: 0px;
    opacity: 0;
  }
}

.copay-success {
  text-align: center;
  font-family: CircularStd; }
  .copay-success .copay-success__checkmark-img {
    margin-top: 174px;
    margin-bottom: 51px;
    width: 141px; }
  .copay-success h1 {
    font-size: 42px;
    line-height: 1.98;
    letter-spacing: -0.5px;
    color: #2f2f2f;
    margin-top: 0px; }
  .copay-success h2 {
    font-size: 42px;
    letter-spacing: -0.3px;
    color: #000000;
    margin-bottom: 130px; }
  .copay-success h3 {
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #394248;
    margin-bottom: 46px;
    padding: 0px 30px; }
  .copay-success button {
    width: 377px;
    height: 75px;
    border-radius: 55px;
    box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
    background-color: #44bdd0;
    font-family: CircularStd;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.4px;
    text-align: center;
    color: #ffffff;
    outline: none;
    transition: all 0.2s ease-in;
    border: none; }
    .copay-success button:disabled {
      background-color: #d3d3d3;
      color: #838383;
      opacity: 0.8; }
    .copay-success button:not(:disabled):hover {
      background-color: #2da2b4;
      cursor: pointer; }
  .copay-success .checkout__certifications-container {
    padding-top: 194px; }

@media screen and (max-width: 768px) {
  .copay-success .copay-success__checkmark-img {
    width: 63px;
    margin-top: 50px;
    margin-bottom: 35px; }
  .copay-success h1 {
    font-size: 36px;
    line-height: 1.6;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #2f2f2f; }
  .copay-success h2 {
    font-size: 20px;
    margin-bottom: 66px; }
  .copay-success h3 {
    font-size: 16px;
    letter-spacing: 0.3px;
    margin-bottom: 26px; }
  .copay-success button {
    width: 232px;
    height: 52px;
    border-radius: 55px;
    font-size: 20px;
    margin-bottom: 90px; }
  .copay-success .checkout__certifications-container {
    display: none; }
  .copay-success .checkout__questions-container {
    margin-top: 1px solid rgba(0, 0, 0, 0.06); } }

.medication__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1.8rem 1rem;
  margin-bottom: 1.25rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 1px solid #eee;
  max-width: 1024px;
  height: auto;
  max-height: 290px; }
  @media screen and (max-width: 768px) {
    .medication__container {
      margin: 0px 0px 26px 0px;
      border-radius: 0px; } }
  .medication__container.selectable:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.1s ease-in; }
  .medication__container .medication__img-container {
    height: 100%;
    width: 200px;
    padding: 2rem; }
    .medication__container .medication__img-container img {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto; }
  .medication__container .medication__details-container {
    width: 80%;
    line-height: 1.5;
    color: #2f2f2f; }
    .medication__container .medication__details-container h3 {
      font-size: 28px;
      font-weight: 500;
      color: #2f2f2f; }
    .medication__container .medication__details-container h4 {
      font-size: 28px;
      font-weight: 400;
      color: #2f2f2f; }
    .medication__container .medication__details-container li {
      font-size: 20px;
      color: rgba(57, 66, 72, 0.5);
      margin-top: 5px; }
    .medication__container .medication__details-container li:first-child {
      font-size: 24px;
      margin-top: 3px; }
    .medication__container .medication__details-container .medication-details--dot-separator {
      font-size: 0.5em;
      position: relative;
      top: -4px; }
  .medication__container div.medication__checkbox-container img.medication__checkmark-img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    transition: opacity 1s ease-in-out; }

@media screen and (max-width: 768px) {
  .medication__container--mobile-highlight {
    border-top: solid 2px #44bdd0; } }

.prescription-section__container p {
  font-size: 20px; }

@media screen and (max-width: 768px) {
  body {
    width: 100%; }
  .medication__img-container {
    display: none; }
  img.medication__checkmark-img {
    width: 35px !important;
    height: 35px !important; }
  .prescription-section__container {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important; }
    .prescription-section__container h2,
    .prescription-section__container p {
      margin-left: 17px; }
    .prescription-section__container p {
      font-size: 16px !important; }
  .medication__details-container {
    width: 100%;
    line-height: 1.5;
    color: #2f2f2f;
    color: rgba(57, 66, 72, 0.79); }
    .medication__details-container h3 {
      font-size: 18px !important; }
    .medication__details-container h4 {
      font-size: 16px !important;
      color: rgba(57, 66, 72, 0.79); }
    .medication__details-container li {
      font-size: 16px !important;
      color: rgba(57, 66, 72, 0.79); }
  div.copay-confirmation__container {
    padding: 0; }
    div.copay-confirmation__container.div {
      padding: 0 1rem; }
  .medication__details-container {
    line-height: 2.2 !important; } }

h1 {
  text-align: center;
  margin: 2rem 0; }

button.medications__continue-button {
  width: 377px;
  height: 75px;
  border-radius: 55px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
  background-color: #44bdd0;
  font-family: CircularStd;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.4px;
  text-align: center;
  color: #ffffff;
  outline: none;
  transition: all 0.2s ease-in;
  border: none; }
  button.medications__continue-button:disabled {
    background-color: #d3d3d3;
    color: #838383;
    opacity: 0.8; }
  button.medications__continue-button:not(:disabled):hover {
    background-color: #2da2b4;
    cursor: pointer; }

@media screen and (max-width: 768px) {
  button.medications__continue-button {
    width: 232px;
    height: 52px;
    font-size: 22px;
    letter-spacing: -0.3px; } }

div.shipping-payment__container {
  width: 100%;
  max-width: 1000px; }
  div.shipping-payment__container input:focus {
    outline: none; }
  div.shipping-payment__container h2 {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.5; }
  div.shipping-payment__container div.shipping-info__container {
    display: flex;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 7px;
    margin-top: 60px;
    margin-bottom: 40px;
    flex-direction: column; }
    div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container {
      width: calc(100% - 80px);
      display: grid;
      grid-column-gap: 22px;
      grid-row-gap: 15px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(5, 1fr); }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container input {
        border: 1px solid rgba(175, 188, 198, 0.55);
        border-radius: 3px;
        width: 100%;
        height: 50px;
        font-family: CircularStd;
        font-size: 16px;
        padding-left: 1rem; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container input::placeholder {
        color: rgba(57, 66, 72, 0.5);
        letter-spacing: 0.3px; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__header {
        grid-column: 1 / 4;
        grid-row: 1; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__street1 {
        grid-column: 1 / 4;
        grid-row: 2; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__street2 {
        grid-column: 4 / 7;
        grid-row: 2; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__city {
        grid-column: 1 / 4;
        grid-row: 3; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__dropdown-select-state {
        grid-column: 4 / 6;
        grid-row: 3;
        width: 100%;
        padding: 0px; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__zip {
        grid-column: 1 / 4;
        grid-row: 4; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__shipping-dropdown {
        grid-column: 1 / 7;
        grid-row: 5;
        padding: 0; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .MuiInputBase-input {
        box-sizing: border-box;
        border: none; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .MuiInputLabel-formControl {
        top: -3px; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .MuiSelect-selectMenu {
        border: 1px solid rgba(175, 188, 198, 0.55);
        border-radius: 3px;
        width: 100%;
        height: 50px;
        font-family: CircularStd;
        font-size: 16px;
        padding-left: 1rem;
        border: none; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .MuiSelect-select {
        position: relative;
        top: -3px; }
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .MuiSelect-select:focus {
        background-color: rgba(0, 0, 0, 0); }
    div.shipping-payment__container div.shipping-info__container div.dropdown-select__state__control,
    div.shipping-payment__container div.shipping-info__container div.shipping-inputs--shipping-dropdown__control {
      padding: 0px;
      align-content: center;
      height: 50px; }
    div.shipping-payment__container div.shipping-info__container div.dropdown-select__state__value-container,
    div.shipping-payment__container div.shipping-info__container div.shipping-inputs--shipping-dropdown__value-container {
      padding-left: 16px; }
    div.shipping-payment__container div.shipping-info__container div.dropdown-select__state__placeholder,
    div.shipping-payment__container div.shipping-info__container div.shipping-inputs--shipping-dropdown__placeholder {
      color: rgba(57, 66, 72, 0.5);
      letter-spacing: 0.3px; }
    div.shipping-payment__container div.shipping-info__container div.shipping-info__save-button-container {
      width: 40px;
      margin: 0 20px;
      color: #44bdd0;
      font-size: 22px; }
    @media screen and (max-width: 768px) {
      div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container {
        width: 100%;
        grid-template-rows: repeat(6, 1fr); }
        div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__header {
          grid-column: 1 / 7; }
        div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__street1 {
          grid-column: 1 / 7; }
        div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__street2 {
          grid-column: 1 / 7;
          grid-row: 3; }
        div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__city {
          grid-column: 1 / 7;
          grid-row: 4; }
        div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__dropdown-select-state {
          grid-column: 1 / 4;
          grid-row: 5; }
        div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__zip {
          grid-column: 4 / 7;
          grid-row: 5; }
        div.shipping-payment__container div.shipping-info__container div.shipping-inputs__container .shipping-inputs__shipping-dropdown {
          grid-row: 6; }
      div.shipping-payment__container div.shipping-info__container div.shipping-info__save-button-container {
        position: absolute;
        right: 10px;
        font-size: 16px;
        letter-spacing: 0.3px; } }
  div.shipping-payment__container div.stripe__container {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    border-radius: 7px; }
    div.shipping-payment__container div.stripe__container input::placeholder {
      color: rgba(57, 66, 72, 0.5);
      letter-spacing: 0.3px; }
    div.shipping-payment__container div.stripe__container div.stripe__card-element-container {
      display: grid;
      grid-column-gap: 22px;
      grid-row-gap: 15px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, 1fr);
      width: calc(100% - 80px); }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__header {
        grid-column: 1 / 6;
        grid-row: 1; }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__card-number {
        grid-column: 1 / 7;
        grid-row: 2; }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__cardholder-name-div {
        grid-column: 1 / 4;
        grid-row: 3; }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__expiry {
        grid-column: 4 / 6;
        grid-row: 3; }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__cvc {
        grid-column: 6 / 7;
        grid-row: 3; }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card--payment-error-message {
        grid-column: 1 / 7;
        grid-row: 4;
        color: red;
        font-family: Arial, Helvetica, sans-serif; }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container div.stripe-card--input-styling {
        border-radius: 3px;
        border: 1px solid rgba(175, 188, 198, 0.55);
        padding: auto 0px auto 16px;
        height: 50px; }
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container div.stripe-card--spacing-wrapper {
        margin: 15px 0px 15px 16px; }
    div.shipping-payment__container div.stripe__container input.cardholder-name--input {
      border: 1px solid rgba(175, 188, 198, 0.55);
      border-radius: 3px;
      width: 100%;
      height: 50px;
      font-family: CircularStd;
      font-size: 16px;
      padding-left: 1rem; }
    @media screen and (max-width: 768px) {
      div.shipping-payment__container div.stripe__container div.stripe__card-element-container {
        width: 100%; }
        div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__cardholder-name-div {
          grid-column: 1 / 7;
          grid-row: 3; }
        div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__expiry {
          grid-column: 1 / 4;
          grid-row: 4; }
        div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card__cvc {
          grid-column: 4 / 6;
          grid-row: 4; }
        div.shipping-payment__container div.stripe__container div.stripe__card-element-container .stripe-card--payment-error-message {
          grid-column: 1 / 7;
          grid-row: 5; } }
  div.shipping-payment__container .checkout-buttons__container {
    display: flex;
    justify-content: center;
    margin: 121px 0 36px; }
  div.shipping-payment__container .continue-checkout-button:not(:disabled):hover {
    background-color: #2da2b4;
    cursor: pointer; }

@media screen and (max-width: 768px) {
  div.shipping-payment__container {
    padding: 32px 0px !important; }
    div.shipping-payment__container h2 {
      font-size: 16px;
      letter-spacing: -0.3px; }
  div.shipping-info__save-button-container {
    font-size: 16px;
    letter-spacing: 0.3px; } }

.StripeElement::placeholder {
  color: rgba(57, 66, 72, 0.5);
  letter-spacing: 0.3px; }

div.welcome-message {
  width: 100%;
  background-color: #44bdd0;
  position: relative;
  padding: 1rem;
  transition: margin 0.3s ease-in-out, height 0.3s ease-in-out, padding 0.3s ease-in-out 0.1s;
  overflow: hidden;
  display: flex;
  justify-items: center; }
  div.welcome-message .welcome-message--text-wrapper {
    width: 1000px;
    margin: 0 auto; }
  div.welcome-message .welcome-message--text {
    font-family: CircularStd;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    padding-right: 20px; }
    div.welcome-message .welcome-message--text .welcome-message__instr-mobile {
      display: none; }
    @media screen and (max-width: 1024px) {
      div.welcome-message .welcome-message--text .welcome-message__instr-mobile {
        display: inline; }
      div.welcome-message .welcome-message--text .welcome-message__instr-lg {
        display: none; } }
  div.welcome-message .welcome-message--close {
    position: absolute;
    top: 25px;
    right: 36px;
    width: 20px;
    height: 22px;
    margin-left: 81px;
    color: white; }
  div.welcome-message .welcome-message--close:hover {
    cursor: pointer; }
  @media screen and (max-width: 768px) {
    div.welcome-message {
      margin-bottom: 0px; }
      div.welcome-message .welcome-message--text {
        font-size: 16px;
        line-height: 1.63; }
      div.welcome-message .welcome-message--close {
        top: 11px;
        right: 9px;
        width: 12px;
        height: 13px; } }
  div.welcome-message.welcome-message--collapsed {
    height: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important; }
  @media screen and (max-width: 576px) {
    div.welcome-message .welcome-message--text {
      font-size: 16px;
      line-height: 1.63; }
    div.welcome-message .welcome-message--close {
      top: 11px;
      right: 9px;
      width: 12px;
      height: 13px; } }
  div.welcome-message.welcome-message--collapsed {
    height: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important; }

