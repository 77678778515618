@charset "UTF-8";
.checkout__form--heading {
  font-family: CircularStd;
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  color: #000000; }

.checkout__intro-text {
  font-family: CircularStd;
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #44bdd0; }

.checkout__steptracker {
  max-width: 798px; }

.checkout__heading-container {
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: space-evenly; }

@media screen and (max-width: 992px) {
  .checkout__heading-container {
    display: flex;
    flex-direction: column;
    height: 8rem;
    justify-content: space-evenly; }
  .checkout__form--heading {
    font-size: 25px; }
  .checkout__intro-text {
    font-size: 16px; } }

.checkout__container {
  width: 800px;
  margin: 45px auto 20px auto;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  box-shadow: 0 3px 7px 1px rgba(224, 224, 224, 0.5); }
  .checkout__container .checkout__form--container {
    display: flex;
    flex-direction: column;
    flex: 5.5;
    background-color: #fbfbfb;
    padding: 35px 50px;
    position: relative;
    box-shadow: 0 3px 7px 1px rgba(224, 224, 224, 0.5);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
    .checkout__container .checkout__form--container h3.checkout__form--heading {
      font-family: CircularStd;
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.67px;
      text-align: left;
      color: #2f2f2f;
      margin-bottom: 5px; }
    .checkout__container .checkout__form--container h2.checkout__form--heading {
      font-family: CircularStd;
      font-style: normal;
      font-weight: bold;
      font-stretch: normal;
      line-height: normal;
      text-align: left;
      color: #2f2f2f;
      margin-bottom: 5px;
      letter-spacing: -0.4px; }
    .checkout__container .checkout__form--container .checkout__intro-text {
      opacity: 0.41;
      font-family: CircularStd;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 25px; }
    .checkout__container .checkout__form--container .MuiTextField-root {
      margin-top: 1rem; }
    .checkout__container .checkout__form--container .MuiInputBase-input {
      border-radius: 3px;
      border: solid 1px rgba(175, 188, 198, 0.55);
      background-color: #ffffff; }
      .checkout__container .checkout__form--container .MuiInputBase-input.MuiOutlinedInput-input {
        border: none;
        /* This makes disabled inputs more visually distinct. */ }
        .checkout__container .checkout__form--container .MuiInputBase-input.MuiOutlinedInput-input:disabled {
          background-color: #eee;
          color: rgba(0, 0, 0, 0.87);
          cursor: not-allowed; }
    .checkout__container .checkout__form--container .MuiFormLabel-root {
      font-family: CircularStd;
      color: rgba(57, 66, 72, 0.5); }
    .checkout__container .checkout__form--container .checkout__survey-fields-container {
      margin-top: 2.6rem; }
    .checkout__container .checkout__form--container .checkout__form--input {
      width: 100%;
      height: 50px;
      border-radius: 3px;
      border: solid 1px rgba(175, 188, 198, 0.55);
      background-color: #ffffff;
      font-size: 16px;
      font-family: CircularStd;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: left;
      color: #000000;
      margin: 10px 0;
      padding: 0 1rem; }
      .checkout__container .checkout__form--container .checkout__form--input::placeholder {
        font-family: CircularStd;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: left;
        color: rgba(57, 66, 72, 0.5); }
      .checkout__container .checkout__form--container .checkout__form--input.error {
        border-color: #e1656f; }
    .checkout__container .checkout__form--container .checkout__toggle-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px; }
      .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container {
        display: flex;
        flex-direction: row; }
        .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container > :first-child {
          margin-right: 15px; }
        .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container .checkout__radio-input-option {
          display: flex;
          flex-direction: row; }
          .checkout__container .checkout__form--container .checkout__toggle-row .checkout__radio-input-container .checkout__radio-input-option label {
            margin-right: 2px; }
    .checkout__container .checkout__form--container .checkout__form--textarea {
      resize: none;
      width: 100%;
      height: 90px;
      border: 1px solid rgba(175, 188, 198, 0.55);
      border-radius: 3px;
      font-size: 16px;
      font-family: CircularStd;
      padding: 1rem;
      margin-bottom: 37px; }
      .checkout__container .checkout__form--container .checkout__form--textarea::placeholder {
        font-family: CircularStd;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: left;
        color: rgba(57, 66, 72, 0.5); }
      .checkout__container .checkout__form--container .checkout__form--textarea.error {
        border: 1px solid red; }
    .checkout__container .checkout__form--container .checkout__label-row {
      display: flex;
      align-items: center; }
      .checkout__container .checkout__form--container .checkout__label-row.patient-customer-checkbox {
        padding: 0 0.5rem; }
        .checkout__container .checkout__form--container .checkout__label-row.patient-customer-checkbox label.checkout__flow--input-label {
          margin-right: 0.5rem; }
      .checkout__container .checkout__form--container .checkout__label-row span.field-required__indicator {
        margin-left: 0.5rem; }
      .checkout__container .checkout__form--container .checkout__label-row span.field-required__indicator,
      .checkout__container .checkout__form--container .checkout__label-row span.field-required__indicator-text {
        font-family: CircularStd;
        font-size: 13px;
        font-weight: 300;
        color: #e1656f; }
    .checkout__container .checkout__form--container .checkout__flow--input-label {
      font-size: 16px;
      font-family: CircularStd;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: rgba(57, 66, 72, 0.5); }
    .checkout__container .checkout__form--container .checkout__form-row {
      margin: 8px 0; }
      .checkout__container .checkout__form--container .checkout__form-row.card-security {
        display: flex;
        flex-direction: row; }
        .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--expiration {
          margin-right: 10px; }
          .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--expiration .checkout__flow--card-expiration {
            display: flex;
            flex-direction: row;
            width: 100%;
            min-width: 250px;
            justify-content: space-between; }
            .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--expiration .checkout__flow--card-expiration div:first-of-type {
              margin-right: 10px; }
        .checkout__container .checkout__form--container .checkout__form-row.card-security .checkout__form-row--cvc {
          max-width: 90px; }
    .checkout__container .checkout__form--container .checkout__city-state-row {
      display: flex;
      justify-content: space-between; }
      .checkout__container .checkout__form--container .checkout__city-state-row input.checkout__form--input {
        width: 50%; }
    .checkout__container .checkout__form--container .checkout__dob-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0;
      margin-bottom: 29px; }
      .checkout__container .checkout__form--container .checkout__dob-row .checkout__dob-row--form-input {
        display: flex;
        flex-direction: row;
        max-width: 100%; }
        .checkout__container .checkout__form--container .checkout__dob-row .checkout__dob-row--form-input > * {
          margin: 0 5px 10px 0; }
      .checkout__container .checkout__form--container .checkout__dob-row label {
        margin-left: 5px; }
    .checkout__container .checkout__form--container .checkout__form--footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
      .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group {
        margin: 51.6px 0px 28px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%; }
        .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group .checkout__form--next-button {
          width: 333px;
          border-radius: 30px;
          box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
          background-color: #44bdd0;
          height: 60px;
          outline: none;
          border: none;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease-in; }
          .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group .checkout__form--next-button:hover {
            background-color: #2da2b4; }
        .checkout__container .checkout__form--container .checkout__form--footer .checkout__form-button-group .checkout__form--button-text {
          font-size: 22px;
          font-family: CircularStd;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          user-select: none; }
    .checkout__container .checkout__form--container p.checkout__question-text {
      font-family: CircularStd;
      font-size: 20px;
      color: rgba(57, 66, 72, 0.65);
      letter-spacing: 0.25px;
      margin-top: 51px;
      text-align: center; }
    .checkout__container .checkout__form--container div.checkout__question-button-container {
      margin-top: 31px; }
      .checkout__container .checkout__form--container div.checkout__question-button-container > * {
        margin-right: 21px; }
      .checkout__container .checkout__form--container div.checkout__question-button-container > :last-child {
        margin-right: 0; }
      .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button {
        width: 127px;
        height: 52px;
        border-radius: 26px;
        font-family: CircularStd;
        font-size: 20px;
        color: #ffffff;
        outline: none;
        border: none; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.yes {
          background-color: #44bdd0; }
          .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.yes:hover {
            background-color: #2da2b4; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.no, .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.back {
          background-color: #afbcc6; }
          .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.no:hover, .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.back:hover {
            background-color: #91a3b1; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button.back {
          border-radius: 30px;
          height: 60px;
          width: 60px; }
        .checkout__container .checkout__form--container div.checkout__question-button-container button.checkout__question-button:hover {
          cursor: pointer; }
    .checkout__container .checkout__form--container .checkout__prescription-required-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 70px;
      line-height: 1.3;
      text-align: center; }
      .checkout__container .checkout__form--container .checkout__prescription-required-container h2.checkout__prescription-required-text {
        font-family: CircularStd;
        font-weight: 500;
        font-size: 28px;
        color: #000000;
        margin-bottom: 20px; }
      .checkout__container .checkout__form--container .checkout__prescription-required-container p {
        font-family: CircularStd;
        font-size: 1.1rem; }
    .checkout__container .checkout__form--container .checkout__transfer-all-rx-container {
      display: flex;
      margin: 10px 0; }
      .checkout__container .checkout__form--container .checkout__transfer-all-rx-container .checkout__radio-button-container {
        margin-left: 25px; }
        .checkout__container .checkout__form--container .checkout__transfer-all-rx-container .checkout__radio-button-container input {
          margin-right: 12px; }
        .checkout__container .checkout__form--container .checkout__transfer-all-rx-container .checkout__radio-button-container label {
          margin-right: 3px; }
  .checkout__container .checkout__purchase-bill--container {
    flex: 3;
    border-radius: inherit;
    background-color: #ffffff;
    padding: 20px 60px; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--img-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px; }
      .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--img-container .checkout__purchase-bill--img {
        display: block;
        height: auto;
        width: auto;
        max-height: 110px;
        max-width: 110px; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--details-container .checkout__purchase-bill--heading {
      font-family: CircularStd;
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2f2f2f; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--details-container .checkout__purchase-bill--purchase-price-total {
      margin: 25px 0;
      font-family: CircularStd;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000; }
    .checkout__container .checkout__purchase-bill--container .checkout__purchase-bill--details-container .checkout__purchase-bill--item-details .checkout__purchase-bill--line-item {
      padding: 25px 0;
      font-family: CircularStd;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: rgba(47, 47, 47, 0.6);
      border-bottom-width: 1px;
      border-bottom-style: dashed; }

.order-processing__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  text-align: center; }

.order-processing__text {
  font-size: 30px;
  font-family: CircularStd;
  font-weight: 450;
  line-height: 1.7; }

@media screen and (max-width: 768px) {
  .order-processing__text {
    font-size: 18px; } }

@media screen and (max-width: 768px) {
  div.checkout__city-state-row div.dropdown-select__container div.dropdown-select__select--dropdown {
    width: 100%; }
  div.checkout__prescription-required-container {
    margin: 0 5% !important; } }

@media screen and (max-width: 768px) {
  div.checkout__purchase-bill--line-item {
    border-bottom-width: 0px !important; } }

@media screen and (max-width: 768px) {
  /* for MedicationDetialView */
  .checkout__container {
    margin: 0;
    width: 100%;
    /* max-width: 100vw; */
    flex-direction: column;
    border-radius: 0;
    box-shadow: none;
    border: none; }
  .checkout__container > .checkout__form--container {
    border-radius: 0;
    padding: 20px 25px; }
  .checkout__container > .checkout__form--container > .checkout__dob-row > .checkout__dob-row--form-input {
    display: flex;
    flex-direction: column; }
  .checkout__purchase-bill--container {
    display: flex;
    order: -2;
    padding: 0 !important;
    height: 91px !important;
    max-height: 91px !important; }
    .checkout__purchase-bill--container div.checkout__purchase-bill--img-container {
      order: 2;
      margin-bottom: 0 !important;
      padding: 10px; }
      .checkout__purchase-bill--container div.checkout__purchase-bill--img-container img {
        height: 61px !important;
        width: auto !important; }
    .checkout__purchase-bill--container div.checkout__purchase-bill--details-container {
      width: 100%;
      padding-left: 1rem; }
      .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--heading {
        font-size: 20px !important;
        margin-top: 10px; }
      .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 66px; }
        .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details > * {
          font-size: 15px !important;
          color: rgba(57, 66, 72, 0.5) !important; }
        .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--line-item.strength:after, .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--line-item.quantity:after {
          content: '•';
          opacity: 0.5;
          padding: 0 0.5rem; }
        .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--purchase-price-total {
          order: 2; }
          .checkout__purchase-bill--container div.checkout__purchase-bill--details-container div.checkout__purchase-bill--item-details div.checkout__purchase-bill--purchase-price-total span.checkout__purchase-bill--total-text {
            display: none; }
  .checkout__purchase-bill--line-item-label {
    display: none; }
  .card-security {
    display: flex;
    flex-direction: column !important; }
  .card-security > * {
    margin-bottom: 4px; }
  .checkout__form--footer > .checkout__form-button-group > a.checkout__form--next-button {
    margin-left: 10px; }
  .checkout__form-logo-group {
    margin: 0px 20px; }
  .checkout__form-logo-group > .checkout__form-logo {
    max-width: calc(20vw - 20px); } }

.step-tracker {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  flex-direction: row !important; }
  .step-tracker .step-tracker__step-container {
    flex: 1 1 32.8%;
    background-color: #fbfbfb;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #44bdd0;
    font-family: CircularStd;
    font-size: 16px;
    transition: box-shadow 0.2s linear;
    box-shadow: inset 0 -2px 0 -1px #a5a9ab;
    margin-left: 7px; }
    .step-tracker .step-tracker__step-container.active {
      box-shadow: inset 0 -1px 0 0 #44bdd0; }
  .step-tracker .step-tracker__step-container:first-child {
    border-radius: 10px 0 0 10px;
    margin-left: 0; }
  .step-tracker .step-tracker__step-container:last-child {
    border-radius: 0 10px 10px 0; }
  @media screen and (max-width: 768px) {
    .step-tracker .step-tracker__step-container {
      height: 5px;
      border-radius: 0;
      box-shadow: none;
      background-color: #c6eaf1; }
      .step-tracker .step-tracker__step-container p {
        display: none; }
    .step-tracker .active {
      background-color: #44bdd0; } }

.checkout__questions-container {
  background-color: #f8fafd;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  padding: 53px 0; }
  .checkout__questions-container h3.checkout__questions-header {
    font-family: CircularStd;
    font-size: 32px;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f2f2f; }
  .checkout__questions-container p.checkout__questions-text {
    font-family: CircularStd;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(47, 47, 47, 0.65); }

.checkout__certifications-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  padding: 82px 0 59px; }
  .checkout__certifications-container p.checkout__certifications-text {
    font-family: CircularStd;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(47, 47, 47, 0.35); }
  .checkout__certifications-container .checkout__certifications-logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%; }
    .checkout__certifications-container .checkout__certifications-logo-container img {
      margin-top: 36px;
      max-height: 57px;
      max-width: 20%; }

@media screen and (max-width: 768px) {
  .checkout__questions-container {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-top: 27px; }
    .checkout__questions-container h3.checkout__questions-header {
      font-size: 26px; }
    .checkout__questions-container p.checkout__questions-text {
      font-size: 15px; } }

@media screen and (max-width: 768px) {
  .checkout__certifications-container {
    padding: 29px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06); }
    .checkout__certifications-container p.checkout__certifications-text {
      display: none;
      padding: 3rem 5% 2rem;
      text-align: center; }
    .checkout__certifications-container .checkout__certifications-logo-container {
      width: 100%;
      justify-content: space-around; }
      .checkout__certifications-container .checkout__certifications-logo-container img {
        max-height: 34px;
        margin-top: 0; } }

@media screen and (max-width: 768px) {
  .checkout__flow--input-label {
    margin-right: 2rem; } }

@media screen and (max-width: 768px) {
  div.checkout__label-row label.checkout__flow--input-label {
    margin-right: 0 !important; }
  div.checkout__label-row span.field-required__indicator-text {
    display: none; } }

.dropdown-select__state {
  width: 30%;
  align-self: center; }

.dropdown-select__state__control {
  height: 48px; }

.dropdown-select__state__placeholder {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(57, 66, 72, 0.5); }

.dropdown-select__shipping__control {
  margin-top: 10px; }

.dropdown-select__shipping__control {
  height: 48px; }

.dropdown-select__shipping__placeholder {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(57, 66, 72, 0.5); }

.dropdown-select__shipping-error__container {
  border-color: #e1656f !important; }

.dropdown-select__shipping-error__control {
  height: 48px; }

.dropdown-select__shipping-error__placeholder {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(57, 66, 72, 0.5); }

.prescription-info_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 100%;
  align-self: center;
  justify-content: center; }

.RectangleTop {
  width: 1440px;
  height: 322px;
  opacity: 0.05;
  background-image: linear-gradient(to bottom, #44bdd0, rgba(0, 151, 175, 0.8)), linear-gradient(to bottom, #fafbfb, #fafbfb); }

@media screen and (max-width: 768px) {
  .checkout__form--next-button {
    width: 225px !important; }
  .checkout__question-text {
    width: 100vw;
    text-align: center;
    line-height: 1.4; }
  .checkout__question-button-container {
    display: flex; } }

.additional-info__header {
  font-family: CircularStd;
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }

@media (max-width: 374px) {
  .checkout__form--next-button {
    width: 175px !important; } }
