@import '../../common/styles/breakpoints';

h1 {
  text-align: center;
  margin: 2rem 0;
}

button.medications__continue-button {
  width: 377px;
  height: 75px;
  border-radius: 55px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
  background-color: #44bdd0;
  font-family: CircularStd;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.4px;
  text-align: center;
  color: #ffffff;
  outline: none;
  transition: all 0.2s ease-in;
  border: none;
  &:disabled {
    background-color: #d3d3d3;
    color: #838383;
    opacity: 0.8;
  }
  &:not(:disabled):hover {
    background-color: darken(#44bdd0, 10%);
    cursor: pointer;
  }
}

@include screen-md {
  button.medications__continue-button {
    width: 232px;
    height: 52px;
    font-size: 22px;
    letter-spacing: -0.3px;
  }
}
