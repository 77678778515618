@import './MedicationFAQs.scss';
@import '../styles/breakpoints';

.medications-list__container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 10%;
}

.medications-list__loading-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 327px);
}

.medications-list__loading-text {
  font-family: CircularStd;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: -0.87px;
}

.medications-list__loading-text:before,
.medications-list__loading-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px;
}

@include screen-md {
  div.medications-list__loading-placeholder {
    h1.medications-list__loading-text {
      font-size: 1.8rem;
    }
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.medications-list__card {
  width: 275px;
  height: 275px;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(228, 234, 237, 0.5), inset 0 -1px 1px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #e4eaed;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 30px;
  transition: 0.5s;
}

.medications-list__card:hover {
  margin-top: 10px;
  transition: 0.5s;
}

.medications-list__alphabetical-divider {
  display: none;
}

.medications-list__card--medimg-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.medications-list__card--medimg {
  max-width: 273px;
  max-height: 200px;
  height: auto;
  width: auto;
}

.medications-list__card--medname {
  width: 275px;
  height: 60px;
  border-width: 1px 0 0 0;
  border-color: #e4eaed;
  border-style: solid;
  background-color: #f5f8fa;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medications-list__card--text {
  font-family: CircularStd;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #252525;
}

.medications-list__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0 40px 0;
}

.medicaitons-list__heading--1 {
  font-size: 42px;
  line-height: 48px;
  font-family: CircularStd;
  letter-spacing: -0.87px;
  font-weight: 500;
  color: #2f2f2f;
}

.medicaitons-list__heading--2 {
  font-family: CircularStd;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #697480;
}

/*//////*/

.medication-show__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.medication-show__drug-display-form {
  margin: 0 calc(100vw * 0.2132);
}
.medication-show__drug-display-form--children {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
}

.medication-show__drug-display-form--form {
  max-width: 285px;
}

.medication-show__drug-display-form--children > .medication-show__drug-display-form--img {
  /*background-color: grey;*/
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 315px;
  max-height: 315px;
  width: auto;
  height: auto;
  margin-right: calc(100vw * 0.075);
}

.medication-show__drug-details {
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  width: 68vw;
  margin: 50px auto 10px auto;
}

.medication-show__drug-details > .medication-show__quicklinks {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  height: auto;
  max-height: 675px;
  border-radius: 5px;
  background-color: rgba(241, 244, 247, 0.55);
  padding: 22px 34px 29px 35px;
  margin-right: 41px;
  margin-bottom: 10px;
}

.medication-show__quicklinks > h4 {
  font-family: CircularStd;
  font-size: 16px;
  font-weight: 400;
  color: #afbcc6;
  line-height: 38px;
  letter-spacing: 0.2px;
}

.medication-show__quicklinks > nav.medication-show__quicklinks-options {
  display: flex;
  flex-direction: column;
  font-family: CircularStd;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 2.13;
  letter-spacing: 0.2px;
}

.medication-show__quicklinks > nav.medication-show__quicklinks-options > a {
  color: #4a4a4a;
}

.medication-show__quicklinks > nav.medication-show__quicklinks-options > a:hover {
  color: #44bdd0;
  cursor: pointer;
}

.medication-show__select-group {
  margin: 35px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.medicaation-show__month-select {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px 0;
}

.medicaation-show__month-select--bubble-selected {
  width: 87px;
  height: 40px;
  border-radius: 20px;
  background-color: #afbcc6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.medicaation-show__month-select--bubble {
  width: 87px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #e4eaed;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
}

.medicaation-show__month-select--bubble:hover {
  width: 87px;
  height: 40px;
  border-radius: 20px;
  background-color: #afbcc6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.medication-show__price {
  font-size: 32px;
  font-weight: 500;
  line-height: 41px;
  text-align: center;
  color: #2f2f2f;
}

span.medication-show__calc-price {
  font-size: 40px;
  color: #44bdd0;
  font-weight: 500;
  line-height: 51px;
}

.medication-show__buy-button {
  width: 282px;
  height: 60px;
  border-radius: 29.9px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.03);
  background-color: #44bdd0;
  border: none;
  outline: none;
  font-size: 26px;
  font-family: CircularStd;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 20px;
  transition: all 0.15s ease-in-out;
  &.disabled {
    background-color: #d3d3d3;
    color: #838383;
    opacity: 0.8;
  }
}

.medication-show__buy-button:not(.disabled):hover {
  background-color: darken(#44bdd0, 10%);
  cursor: pointer;
}

.medication-show__disclaimer {
  font-size: 12px;
  font-family: Helvetica;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: 0.25px;
  text-align: center;
  color: #000000;
  opacity: 0.3;
}

.medication-show__details-heading {
  font-size: 28px;
  font-family: CircularStd;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 9px;
}

.medication-show__details-body {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.7;
  color: #474747;
}

.medication-show__details-section {
  margin-bottom: 60px;
}

.medication-show__details-section-wrapper {
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 650px;
}

.medication-show__details-section-wrapper.expanded {
  max-height: none;
}

.medication-show__details-section-container--fade {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.75) 50%,
    rgba(255, 255, 255, 1) 70%
  );
  height: 280px;
  margin-top: -280px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 40px;
}

.medication-show__details-section-container--fade.hide {
  display: none;
}

.medication-show__details-section-container--fade > .medication-show__show-more-button {
  width: 125px;
  height: 41px;
  border-radius: 20.5px;
  background-color: #afbcc6;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
  margin-bottom: -20px;
  transition: all 250ms linear;
}

.medication-show__details-section-container--fade > .medication-show__show-more-button:hover {
  background-color: #44bdd0;
}

.medication-show__drug-details--mobile {
  display: none;
}

/* Intended for mobile viewports */
@include screen-md {
  /* .medications-list__header {
    margin-left: 40px;
  } */

  .medication-show__details-section {
    margin-bottom: 0;
  }

  .medications-list__header > * {
    text-align: center;
  }

  .medications-list__container > a {
    width: 100%;
  }

  .medications-list__card {
    flex-direction: row;
    height: auto;
    width: 100%;
    margin: 0;
  }

  .medications-list__card--medname {
    background-color: #fff;
    border-width: 0;
    box-shadow: 3px 3px 7px 0 rgba(226, 232, 235, 0.42);
    justify-content: flex-start;
    padding-left: 19px;
    width: 100%;
  }

  .medications-list__card--medimg-container {
    display: none;
  }

  .medications-list__alphabetical-divider {
    display: flex;
    margin: 25px 0 0 1.5px;
  }

  .medications-list__alphabetical-divider:first-of-type {
    margin-top: 0;
  }

  .medications-list__alphabetical-divider > .medications-list__alphabetical-divider--text {
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f2f2f;
  }

  /* for MedicationDetialView */
  .medication-show__drug-display-form {
    margin: 0 46px 0 46px;
  }

  .medication-show__drug-display-form--form {
    font-family: CircularStd;
    max-width: 77vw;
    display: flex;
    flex-direction: column;
  }

  .medication-show__drug-display-form--img {
    height: auto !important;
    width: auto !important;
    max-height: calc(100vw * 0.2) !important;
    max-width: calc(100vw * 0.76) !important;
    margin-right: 0 !important;
  }

  .medication-show__drug-display-form--children {
    flex-direction: column;
    background-color: #fff !important;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 0;
  }

  .medicaitons-list__heading--1 {
    font-size: 32px;
    line-height: 1;
    letter-spacing: -0.7px;
    text-align: center;
    margin: 19px 0 8px 0;
  }

  span.medication-show__calc-price {
    text-align: center;
    font-size: 26px;
    line-height: 33px;
  }

  .medication-show__select-group {
    margin-top: 19px;
  }

  .medication-show__drug-details {
    display: none;
  }

  .medication-show__drug-details--mobile {
    display: flex;
  }

  .medication-show__checkout-group {
    display: flex;
    width: 77vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .medication-show__select-group {
    width: 100%;
  }

  .medication-show__drug-details--mobile-about {
    margin: 34px 15px 41px 21px;
  }

  .medication-show__details-divider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #ececec;
    font-family: CircularStd;
    font-size: 18px;
    font-weight: 450;
    line-height: 1.85;
    padding-left: 21px;
    padding-right: 19px;
    margin-bottom: 0;
    height: 46px;
  }

  .medication-show__details-divider:hover {
    cursor: pointer;
  }

  .medication-show__details-section-text {
    font-family: HelveticaNeue;
    padding: 20px 21px 35px 21px;
    border-bottom: solid 1px #ececec;
    font-size: 14px;
    color: #474747;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 0;
    opacity: 0.7;
  }

  .medication-show__details-section > div:nth-of-type(2) {
    border-top: solid 1px #ececec;
  }
}

.goodrx-banner__container {
  background-color: #ffee59;
  padding: 0 5rem;
  position: absolute;
  width: 100vw;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: CircularStd;
  color: #544f1d;
  font-size: 21px;
  text-align: center;

  &.hide {
    display: none;
  }

  img.goodrx-banner__logo-img {
    height: 21px;
    padding-left: 0.45rem;
    vertical-align: middle;
  }

  div.goodrx-banner__close-button {
    justify-self: flex-end;
    font-size: 15px;
    user-select: none;
    cursor: pointer;
  }
}

@include screen-md {
  .goodrx-banner__container {
    font-size: 16px;
    height: 78px;
    // text-align: left;
    padding: 0 1rem;
    line-height: 1.5;

    span.goodrx-banner__text {
      margin-left: 0;
      margin-right: 10px;
    }

    img.goodrx-banner__logo-img {
      height: 16px;
      padding-left: 0.4rem;
    }

    div.goodrx-banner__close-button {
      margin-right: 8px;
    }
  }
}
