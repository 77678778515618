@import 'breakpoints';
.page-not-found__container {
  display: flex;
  font-size: 28px;
  height: calc(100vh - 180px - 474px);
  justify-content: center;
  min-height: 300px;
  padding-top: 5rem;
  width: 100%;
}

@include screen-lg {
  .page-not-found__container {
    height: calc(100vh - 172px - 60px);
    min-height: 200px;
  }
}
