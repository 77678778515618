@import '../styles/breakpoints';

div.order-complete__container {
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: CircularStd;
  padding: 53.3px 25% 58px;

  img.order-complete__checkmark-img {
    max-width: 64px;
    max-height: 64px;
    margin-bottom: 34.4px;
  }

  h2.order-complete__heading {
    font-size: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
  }

  h3.order-complete__subheading {
    width: 382px;
    font-family: CircularStd;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-top: 1rem;
  }

  p.order-complete__text-top {
    font-family: CircularStd;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #394248;
    margin-top: 2rem;
  }

  p.order-complete__text-bottom {
    font-family: CircularStd;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #394248;
    margin-top: 1rem;
  }

  p.order-complete__text {
    // height: 25px;
    font-family: CircularStd;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #394248;
    margin-top: 3rem;
  }

  input.order-complete__password-input {
    border: 1px solid #d7d7d7;
    height: 50px;
    width: 315px;
    border-radius: 3px;
    padding-left: 1rem;
    outline: none;
    margin-bottom: 36px;

    &::placeholder {
      font-family: HelveticaNeue;
      font-size: 16px;
      color: rgba(77, 77, 77, 0.43);
    }
  }
}

@include screen-md {
  div.order-complete__container {
    padding: 42px 8% 33px;
  }
  .order-complete__subheading {
    width: 340px !important;
    font-size: 26px !important;
  }
}

.support-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 226px;
  height: 46px;
  border-radius: 55px;
  box-shadow: 0 12px 31px 0 rgba(0, 0, 0, 0.13);
  background-color: #4bbdcf;
  margin-top: 2rem;
}

.support-button__text {
  width: 136px;
  height: 23px;
  font-family: CircularStd;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
