@import 'breakpoints';

.about-truepill-pharmacy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 86.25rem;
  margin: 5rem 0;
}

.top-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 4rem;
  width: 86.25rem;
}

.top-left {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  order: 1;
}

.top-right {
  order: 2;
}

.icon-item--container {
  display: flex;
}

.icon-container {
  display: flex;
  justify-content: center;
  width: 5rem;
  margin-right: 3rem;
}

.raft-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.airplane-icon {
  width: 50px;
  height: 41px;
  object-fit: contain;
}

.box-icon {
  width: 40px;
  height: 53px;
  object-fit: contain;
}

.icon-item--text-title {
  height: 36px;
  font-family: CircularStd;
  font-size: 28px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a3235;
}

.icon-item--text-subtitle {
  width: 370px;
  height: 63px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: rgba(40, 48, 51, 0.65);
}

.bottom-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 4rem;
  width: 86.25rem;
  margin-top: 8rem;
  // height: 86.25rem;
}

.bottom-left {
  order: 3;
}

.bottom-right {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  width: 547px;
  order: 4;
}

.location-map {
  width: 669px;
  // margin-top: 4rem;
  object-fit: contain;
}

.more-info-item--text-title {
  width: 357.7px;
  height: 28px;
  font-family: CircularStd;
  font-size: 22px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a3235;
}
.more-info-item--text-subtitle {
  width: 547px;
  height: 54px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: rgba(40, 48, 51, 0.65);
}

@media (max-width: 1380px) {
  .about-truepill-pharmacy-container {
    justify-content: center;
    width: 100vw;
    overflow: hidden;
  }

  .top-section-container {
    flex-direction: column;
    align-items: center;
    // width: 100vw;
  }
  .top-left {
    // width: 100vw;
    order: 2;
    margin-top: 4rem;
  }
  .top-right {
    // width: 100vw;
    order: 1;
    img {
      width: 400px;
    }
  }
  .bottom-left {
    order: 3;
    // margin-top: 4rem;
  }
  .bottom-right {
    order: 4;
    // margin-top: 8rem;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  .icon-item--text-title {
    font-size: 18px;
  }
  .icon-item--text-subtitle {
    font-size: 18px;
    // width: auto;
  }
  .top-section-container {
    flex-direction: column;
    width: 100%;
  }
  .bottom-section-container {
    flex-direction: column;
    width: 50rem;
  }

  .about-truepill-pharmacy-container {
    height: 120rem;
    width: auto;
  }
  .location-map {
    max-width: 90vw;
  }
}

@include screen-lg {
  .about-truepill-pharmacy-container {
    width: 100vw;
  }
  .top-section-container {
    width: auto;
    padding: 0 5%;
    .icon-item--text-title {
      font-size: 18px;
    }
    .icon-item--text-subtitle {
      width: 80%;
    }
  }
  .bottom-section-container {
    width: auto;
    padding: 0 4%;
  }
  .bottom-section-container {
    width: 100vw;
    align-items: center;
    .icon-item--text-title {
      padding: 0 2rem;
      font-size: 22px;
    }
    .icon-item--text-subtitle {
      font-size: 18px;
      padding: 0 2rem;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: rgba(40, 48, 51, 0.65);
      margin-left: 10%;
      margin-right: 10%;
      width: 200px;

      // padding: 0 10%;
    }
  }
  .bottom-right {
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
}

@include screen-md {
  .about-truepill-pharmacy-container {
    height: auto;
  }
  .location-map {
    max-width: 400px;
  }

  .top-section-container {
    width: 100%;
  }
  .top-section-container {
    flex-direction: column;
    width: 100%;
    .icon-container {
      // width: 40px;
    }
    .icon-item--text-container {
      // margin-left: 2rem;
      width: 100%;
      height: 50px;
    }
  }

  .top-right {
    // width: 100vw;
    order: 1;
    img {
      max-width: 400px;
    }
  }

  .bottom-section-container {
    flex-direction: column;
    width: 100%;
  }

  .bottom-section-container {
    width: 100vw;
    img {
      width: 90vw;
    }
    .icon-item--text-title {
      font-size: 22px;
      height: 60px;
      margin: 1rem 10%;
    }
    .icon-item--text-subtitle {
      font-size: 18px;
      padding: 0 2rem;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: rgba(40, 48, 51, 0.65);
      width: 90%;
      // margin: 0 10%;
    }
  }
  .bottom-right {
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    overflow: hidden;
    .more-info-item--text-title {
      width: 100%;
    }
    .more-info-item--text-subtitle {
      width: 100%;
    }
  }
}
