@import '../../common/styles/breakpoints';

.confirm-order__container {
  h2 {
    margin-top: 60px;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  @include screen-md {
    h2 {
      margin: 39px 10px 28px 17px;
      font-size: 20px;
    }
  }

  width: 100%;
  max-width: 1000px;
}
