/* FrequentlyAskedQuestions */
.faq__header {
  margin: 68px 0 49px;
}

.faq__header > .faq__heading--1 {
  color: #2f2f2f;
  letter-spacing: -0.25px;
  font-family: CircularStd;
  font-weight: 450;
  font-size: 42px;
  text-align: center;
}

h1.faq__heading--1 {
  font-weight: 400;
}

.faq__search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}

.faq__search-bar--input-container {
  height: 61px;
  width: 865px;
  display: flex;
  align-items: center;
  border: 1px solid #e4eaed;
  border-radius: 5px;
}

.faq__search-bar--input {
  height: 56px;
  width: 100%;
  border: none;
  background: #ffffff url('../../assets//images//search.png') no-repeat scroll 19px 20px;
  font-size: 18px;
  color: #697480;
  opacity: 0.5;
  text-indent: 50px;
}

.faq__search-bar--input:focus {
  outline: none;
}

/* Questions */
.faq__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CircularStd;
  margin: 0 19.25vw;
}

.faq__container > * {
  margin-bottom: 25px;
}

.faq-group__header {
  color: #afbcc6;
  font-size: 28px;
  font-weight: bold;
  width: 837px;
}

.faq-question__container {
  width: 837px;
  min-height: 85px;
  max-height: 85px;
  overflow: hidden;
  background-color: rgba(241, 244, 247, 0.55);
  border-radius: 10px;
  padding: 30px 22px 0;
  transition: all 0.5s ease-in-out;
}

.faq-question__container.active {
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  max-height: 9001px;
}

.faq-question__header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 26px;
}

.faq-question__header-icon.open {
  max-height: 26px;
  max-width: 26px;
}

.faq-question__header-icon.closed {
  max-height: 12px;
  max-width: 18px;
}

h1.faq-question__title {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
  transition: color 0.25s;
}

h1.faq-question__title.active {
  color: #44bdd0;
}

.faq-question__body {
  color: #474747;
  font-size: 15px;
  line-height: 23px;
  max-height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.faq-question__body.active {
  opacity: 1;
  max-height: 10000px;
  padding: 25px 0;
}

.faq__heading--no-results {
  font-size: 34px;
  font-weight: bold;
  color: #afbcc6;
}

.scaffold-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8.5%;
  font-family: CircularStd;
  padding: 3.5% 0;
}

.page-title,
.section-title {
  font-weight: bold;
  letter-spacing: -0.25px;
  color: #1c2b36;
}

.page-title {
  font-size: 42px;
}

.section-title {
  font-size: 18px;
  line-height: 25px;
}

.scaffold__content {
  padding: 2.5% 0;
  width: 100%;
}

.scaffold__content > *,
.faq-question__body > * {
  margin-bottom: 12px;
}

.p-text,
.scaffold__content > ul {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.25px;
  color: rgba(28, 43, 54, 0.84);
}

.bold-text {
  font-weight: bold;
}

.underline-text {
  text-decoration: underline;
}

.scaffold__content > ul,
.faq-question__body > ul {
  list-style: disc inside none;
}

@media (max-width: 1024px) {
  div.faq__search-bar {
    width: 82%;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  div.faq__container {
    width: 82%;
    margin: auto;

    div.faq-group__header,
    div.faq-question__container {
      width: 100%;
      max-height: none;

      h1.faq__heading--1 {
        font-weight: 300;
        text-align: center;
      }

      h1.faq-question__title {
        margin-right: 1rem;
        font-size: 16px;
      }

      div.faq-question__body {
        p.p-text {
          font-size: 14px;
        }
      }
    }
  }
}
