@import '../../common/styles/breakpoints';

.medication__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1.8rem 1rem;
  margin-bottom: 1.25rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 1px solid #eee;
  max-width: 1024px;
  height: auto;
  max-height: 290px;

  @include screen-md {
    margin: 0px 0px 26px 0px;
    border-radius: 0px;
  }

  &.selectable:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.1s ease-in;
  }

  .medication__img-container {
    height: 100%;
    width: 200px;
    padding: 2rem;

    img {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
    }
  }

  .medication__details-container {
    width: 80%;
    line-height: 1.5;
    color: #2f2f2f;

    h3 {
      font-size: 28px;
      font-weight: 500;
      color: #2f2f2f;
    }

    h4 {
      font-size: 28px;
      font-weight: 400;
      color: #2f2f2f;
    }

    li {
      font-size: 20px;
      color: rgba(57, 66, 72, 0.5);
      margin-top: 5px;
    }

    li:first-child {
      font-size: 24px;
      margin-top: 3px;
    }

    .medication-details--dot-separator {
      font-size: 0.5em;
      position: relative;
      top: -4px;
    }
  }

  div.medication__checkbox-container {
    img.medication__checkmark-img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      transition: opacity 1s ease-in-out;
    }
  }
}

@include screen-md {
  .medication__container--mobile-highlight {
    border-top: solid 2px #44bdd0;
  }
}

.prescription-section__container {
  p {
    font-size: 20px;
  }
}

@include screen-md {
  body {
    width: 100%;
  }
  .medication__img-container {
    display: none;
  }
  img.medication__checkmark-img {
    width: 35px !important;
    height: 35px !important;
  }
  .prescription-section__container {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    h2,
    p {
      margin-left: 17px;
    }
    p {
      font-size: 16px !important;
    }
  }
  .medication__details-container {
    width: 100%;
    line-height: 1.5;
    color: #2f2f2f;
    color: rgba(57, 66, 72, 0.79);
    h3 {
      font-size: 18px !important;
    }

    h4 {
      font-size: 16px !important;
      color: rgba(57, 66, 72, 0.79);
    }

    li {
      font-size: 16px !important;
      color: rgba(57, 66, 72, 0.79);
    }
  }
  // .medication__container {
  //   height: 168px;
  // }
  div.copay-confirmation__container {
    padding: 0;
    &.div {
      padding: 0 1rem;
    }
  }
  .medication__details-container {
    line-height: 2.2 !important;
  }
}
